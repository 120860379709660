import React, { useState, useEffect } from "react";
import { Host, Port } from "../../Routes/apiConfig";
import {Select,Menu,Table,DatePicker,Button,Modal,message,Form,Input,Space,Layout,Row,Col} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../../LayoutContext";
import moment from "moment";
import MyHeader from "../../compo/Header";
import Barchart from "../../Charts/BarChart";
import Linechart from "../../Charts/lineChart";
import Areachart from "../../Charts/AreaChart";
import Charts from "../../compo/Charts";

const BuyBallsDaily = () => {
  const { isLtr } = useLayout();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchDailyBallsBarChartData();
  }, []);

  /************************customStyles**********************************/
  const containerStyle = {
    marginBlockStart: "2rem",
    marginInline: "1rem",
  };

  const customColors = ["#7684af"];

  const navLinkStyle = {
    textDecoration: "none",
    padding: "8px 16px",
    margin: "4px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    color: "#333",
    backgroundColor: "#f4f4f4",
    transition: "background-color 0.2s",
  };

  const customStateHeader = () => (
    <div style={{ paddingBlock: "1.5rem", background: "#e5eaf9" }}>
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
          paddingInlineStart: "1rem",
        }}
      >
        States
      </span>
    </div>
  );

  const [barChartData, setBarChartData] = useState([]);
  const [dailyBallsBarChartData, setDailyBallsBarChartData] = useState([]);
  const [thirdBarChartData, setThirdBarChartData] = useState([]);

  /**********************************Fetch Bar Chart Data*********************************************/

  const fetchDailyBallsBarChartData = async (startTime, endTime) => {
    try {
      const response = await Axios.get(
        `${Host}:${Port}/admin/state_xchange_money?startTime=${
          startTime ? startTime : "2022-11-08"
        }&endTime=${endTime ? endTime : "2023-11-08"}`,
        {
          headers: {
            "hyazs-session-key": sessionKey,
            "HYAZS-API-KEY":
              "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
          },
        }
      );
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else setDailyBallsBarChartData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  /*************************************Fetch Bar Chart Data******************************************/
  const handleStartTimeChange = (date, dateString) => {
    console.log("THE START TIME IS:", dateString);
    setStartTime(dateString);
    setSelectedDateRange("Filter");
  };

  const handleEndTimeChange = (date, dateString) => {
    console.log("THE END TIME IS:", dateString);
    setEndTime(dateString);
    setSelectedDateRange("Filter");
  };

  const handleGetData = () => {
    if (selectedDateRange !== "Filter") {
      setSelectedDateRange("Filter");
    }
    fetchDailyBallsBarChartData(startTime, endTime);
  };
  /***************************************************************************************************/
  const [selectedDateRange, setSelectedDateRange] = useState("Filter");

  const renderBarchart = () => {
    const handleChange = (value) => {
      setSelectedDateRange(value);

      if (value === "last_7_days") {
        const last7Days = moment().subtract(6, "days");
        const Days = last7Days.format("YYYY-MM-DD");
        const currentDay = moment().format("YYYY-MM-DD");
        setStartTime(Days);
        setEndTime(currentDay);
        fetchDailyBallsBarChartData(Days, currentDay);
        setStartTime(null);
        setEndTime(null);
      } else if (value === "last_month") {
        const lastMonth = moment().subtract(1, "months").subtract(1, "days");
        const Days = lastMonth.format("YYYY-MM-DD");
        const currentDay = moment().format("YYYY-MM-DD");
        setStartTime(Days);
        setEndTime(currentDay);
        fetchDailyBallsBarChartData(Days, currentDay);
        setStartTime(null);
        setEndTime(null);
      } else if (value === "last_3_month") {
        const lastMonth = moment().subtract(3, "months").subtract(1, "days");
        const Days = lastMonth.format("YYYY-MM-DD");
        const currentDay = moment().format("YYYY-MM-DD");
        setStartTime(Days);
        setEndTime(currentDay);
        fetchDailyBallsBarChartData(Days, currentDay);
        setStartTime(null);
        setEndTime(null);
      } else if (value === "last_6_month") {
        const lastMonth = moment().subtract(6, "months").subtract(1, "days");
        const Days = lastMonth.format("YYYY-MM-DD");
        const currentDay = moment().format("YYYY-MM-DD");
        setStartTime(Days);
        setEndTime(currentDay);
        fetchDailyBallsBarChartData(Days, currentDay);
        setStartTime(null);
        setEndTime(null);
      } else if (value === "last_year") {
        const lastYear = moment().subtract(1, "years").subtract(1, "days");
        const Days = lastYear.format("YYYY-MM-DD");
        const currentDay = moment().format("YYYY-MM-DD");
        setStartTime(Days);
        setEndTime(currentDay);
        fetchDailyBallsBarChartData(Days, currentDay);
        setStartTime(null);
        setEndTime(null);
      }
    };
    const dailyBallsfilteredData = dailyBallsBarChartData.filter(
      (item) => item.creation_time !== null
    );
    const nullCreationTimeData = dailyBallsBarChartData.filter(
      (item) => item.creation_time === null
    );
    return (
      <>
        <DatePicker
          value={startTime ? moment(startTime) : null}
          onChange={handleStartTimeChange}
          size="default"
          placeholder="From Date"
        />
        <DatePicker
          value={endTime ? moment(endTime) : null}
          onChange={handleEndTimeChange}
          size="default"
          placeholder="To Date"
        />
        <Button onClick={handleGetData}>Get</Button>
        <Space wrap>
          <Select
            value={selectedDateRange}
            style={{ width: 150 }}
            onChange={handleChange}
            options={[
              { value: "last_7_days", label: "Last 7 days" },
              { value: "last_month", label: "Last Month" },
              { value: "last_3_month", label: "Last three Month" },
              { value: "last_6_month", label: "Last six Month" },
              { value: "last_year", label: "Last Year" },
              // { value: 'disabled', label: 'Disabled', disabled: true },
            ]}
          />
        </Space>
        <div style={{ textAlign: "center" }}>
          <Row gutter={26} justify="center">
            <Col span={10}>
              <Barchart
                data={dailyBallsfilteredData}
                xKey="creation_time"
                yKey="Class of 100"
                colors={customColors}
                chartTitle="فئة 100"
                chartSubTitle={
                  nullCreationTimeData.length > 0
                    ? `المجموع: ${
                        nullCreationTimeData[0]["Class of 100"] || "لايوجد"
                      }`
                    : ""
                }
              />
            </Col>
            <Col span={10}>
              <Barchart
                data={dailyBallsfilteredData}
                xKey="creation_time"
                yKey="Class of 500"
                colors={customColors}
                chartTitle="فئة 500"
                chartSubTitle={
                  nullCreationTimeData.length > 0
                    ? `المجموع: ${
                        nullCreationTimeData[0]["Class of 500"] || "لايوجد"
                      }`
                    : ""
                }
              />
            </Col>
            <Col span={10}>
              <Barchart
                data={dailyBallsfilteredData}
                xKey="creation_time"
                yKey="Class of 1000"
                colors={customColors}
                chartTitle="فئة 1000"
                chartSubTitle={
                  nullCreationTimeData.length > 0
                    ? `المجموع: ${
                        nullCreationTimeData[0]["Class of 1000"] || "لايوجد"
                      }`
                    : ""
                }
              />
            </Col>
            <Col span={10}>
              <Barchart
                data={dailyBallsfilteredData}
                xKey="creation_time"
                yKey="Class of 2000"
                colors={customColors}
                chartTitle="فئة 2000"
                chartSubTitle={
                  nullCreationTimeData.length > 0
                    ? `المجموع: ${
                        nullCreationTimeData[0]["Class of 2000"] || "لايوجد"
                      }`
                    : ""
                }
              />
            </Col>
            <Col span={10}>
              <Barchart
                data={dailyBallsfilteredData}
                xKey="creation_time"
                yKey="Class of 5000"
                colors={customColors}
                chartTitle="فئة 5000"
                chartSubTitle={
                  nullCreationTimeData.length > 0
                    ? `المجموع: ${
                        nullCreationTimeData[0]["Class of 5000"] || "لايوجد"
                      }`
                    : ""
                }
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
  return (
    <>
      <Layout dir={isLtr ? "ltr" : "rtl"}>
        <MyHeader
          DrawerTitle="This is the drawer title"
          DrawerFooter="This is the drawer footer"
        />
        {customStateHeader()}
        <div style={containerStyle}>
          <div style={{ marginBlockStart: "1rem" }}>{renderBarchart()}</div>
        </div>
      </Layout>
    </>
  );
};

export default BuyBallsDaily;
