import React, { useState, useEffect } from "react";
import { Host,Port} from '../Routes/apiConfig';
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import {  Table,  DatePicker,  Button,  Modal,  Form,  message,  Input,  Space,  Layout,} from "antd";
import { useNavigate } from "react-router-dom";
import {SearchOutlined,ClockCircleOutlined,CloseCircleOutlined,} from "@ant-design/icons";
import Cookies from "js-cookie";
import Axios from "axios";
import moment from 'moment';
import { useLayout } from "../LayoutContext";
import MyHeader from "../compo/Header";
import './pages-css/internalUsers.css'
import { useTranslation } from "react-i18next";

const CheckPredictions = ({colorTheme}) => {
  const { isLtr } = useLayout();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    const sessionKey = Cookies.get("sessionKeySwin");
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);
  /*********************************************************/
  const [data, setData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }
  /*********************************************************/
  const fetchData = async () => {
    try {
      setLoading(true);
      const checkPredictionsGetApi=`${Host}:${Port}/admin/games/get-uncertain-with-predictions`;
      const response = await Axios.get(checkPredictionsGetApi,headers);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };
  /*********************************************************/
  const editRecord = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
  };

  /*********************************************************/
  const handleProcessClick = async (record,processType) => {
    try {
      const checkPredictionsProcessApi=`${Host}:${Port}/admin/games/get-uncertain-with-predictions`;
      const body={
        type: processType==="process"?"process":"cancel",
        ids: [record.game],
      };
      const response = await Axios.put(checkPredictionsProcessApi,body,headers);

      // Check the response and update the UI accordingly
      if (response.data.statusCode < 0) {
        message.error(response.data.message);
      } else {
        // If the request is successful, you may want to update the data or perform other actions
        processType==="process"?message.success("Processed successfully"):message.success("canceled successfuly");
        fetchData(); // Assuming fetchData updates the state with the latest data
      }
    } catch (error) {
      processType==="process"?console.error("Error processing data:", error):console.error("Error canceling data:", error);
    }
  };
  /*********************************************************/

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: t("formLabels.predictionsInfo.game"),
      dataIndex: "game",
      key: "game",
      // sorter: (a, b) => a.key - b.key,
      onFilter: (value, record) => record.game.toString().includes(value), // Use 'id' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.predictionsInfo.searchGame")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },

    {
      title: t("formLabels.predictionsInfo.date"),
      dataIndex: "startTime",
      key: "startTime",
      render: (value) => {
        return value !== null
          ? moment(value).format("YYYY-MM-DD HH:mm:ss")
          : "none";
      },
      onFilter: (value, record) => {
        const filterDate = moment(value).startOf("day");
        const recordDate = moment(record.startTime).startOf("day");
        return filterDate.isSame(recordDate);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            placeholder={t("formLabels.predictionsInfo.searchDate")}
            value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
            onChange={(date) =>
              setSelectedKeys(date ? [date.format("YYYY-MM-DD")] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.predictionsInfo.activePredictions"),
      dataIndex: "activePredictions",
      key: "activePredictions",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: t("formLabels.predictionsInfo.demoPredictions"),
      dataIndex: "demoPredictions",
      key: "demoPredictions",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: t("formLabels.predictionsInfo.status"),
      dataIndex: "statusText",
      key: "statusText",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.statusText.toLowerCase().includes(value.toLowerCase()),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.predictionsInfo.searchStatus")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.predictionsInfo.checkOnWeb"),
      dataIndex: "googleSearch",
      key: "googleSearch",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button className="custom-edit-button" type="primary" icon={<ClockCircleOutlined />} onClick={() =>handleProcessClick(record,'process')}>{t("table.process")}</Button>
          <Button className="custom-delete-button" type="danger" icon={<CloseCircleOutlined />} onClick={() => handleProcessClick(record,'cancel')}>{t("table.cancel")}</Button>
        </Space>
      ),
    },
  ];
  const customTableHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      >
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
        }}
      >
        {t("menu.internal_users")}
      </span>
      {/* <Button type="primary" onClick={() => setVisible(true)}>
        {t("table.add")}
      </Button> */}
    </div>
  );
  return (
    <Layout dir={isLtr ? "ltr" : "rtl"}>
      <div className={`modern-table-container ${colorTheme==="dark"?"dark-mode":''}`}>
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />

        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          rowKey="game"
          pagination={{ defaultPageSize: 10 }}
          onChange={fetchData}
          
          // expandable={{
          //   expandedRowRender: () => (
          //     <Button onClick={() => setVisible(true)}>Add Record</Button>
          //   ),
          // }}
          title={customTableHeader}
          loading={loading}
          scroll={{ x: '100%' }}
        />
      </div>
    </Layout>
  );
};

export default CheckPredictions;
