import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import Cookies from "js-cookie";
import Axios from "axios";
import './main.css'
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush ,
} from "recharts";
import { useLayout } from "../../src/LayoutContext";

const Barchart = ({ data, xKey, yKey, colors, chartTitle, chartSubTitle,width,height,ticks}) => {
  const { isLtr } = useLayout();
  const tickProps = isLtr
    ? {
        fontSize: 12,
        fontWeight: "bold",
        angle: 20,
        textAnchor: "end",
        dy: 25,
        dx: 55,
      }
    : {
        fontSize: 12,
        fontWeight: "bold",
        angle: -20, // Adjust the angle for RTL
        textAnchor: "start", // Adjust textAnchor for RTL
        dy: 0,
        dx: 10, // Adjust dx for RTL
      };

      const tickPropsY = isLtr
    ? {
        fontSize: 12,
        fontWeight: "bold",
        angle: 0,
        textAnchor: "middle",
        dx:-6,
      }
    : {
        fontSize: 12,
        fontWeight: "bold",
        angle: 0,
        textAnchor: "left",
      };

  return (
    <Layout  dir={isLtr ? "ltr" : "rtl"}>
      <div className="main-container" style={{ width: '700'}}>
        <h2>{chartTitle}</h2>
        <h3>{chartSubTitle}</h3>
        <ResponsiveContainer width={width} height={height}>
          <BarChart data={data} margin={{bottom: 15}}>
            <CartesianGrid strokeDasharray="10" vertical={false}/>
            <XAxis dataKey={xKey} reversed={true} tick={ticks?tickProps:undefined} />
            <YAxis dataKey={yKey} tick={ticks?tickPropsY:undefined} />
            <Tooltip animationEasing="ease-in" />
            <Legend verticalAlign="top" align="right" />
            {/* {Array.from({ length: barNumber }).map((_, index) => (
            <Bar key={index} dataKey={datakeysBarsValues[index]} fill={colors[index % colors.length]} animationDuration={1000}>
             </Bar>
           ))} */}
            <Bar dataKey={yKey} fill={colors} animationDuration={1000} />
           {/* <Brush dataKey={xKey} height={30} stroke="#8884d8"/> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Layout>
  );
};

export default Barchart;
