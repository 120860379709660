import React, { useState, useEffect } from "react";
import { Host, Port } from "../../Routes/apiConfig";
import {Pagination,Card,Select,Menu,Table,DatePicker,Button,Modal,Dropdown,message,Form,Input,Space,Layout,Row,Col} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../../../src/LayoutContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MyHeader from "../../compo/Header";
import LineChart from "../../Charts/lineChart";
import '../pages-css/states.css'
const CompetitionsWinnings = ({colorTheme}) => {
  const { t, i18n } = useTranslation();
  const { isLtr } = useLayout();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  const [competitionsWinningsLineChartData, setCompetitionsWinningsLineChartData] = useState([]);
  const [competitionsData, setCompetitionsData] = useState([]);
  const [competitionValue, setCompetitionValue] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([1, 7, 14, 3, 12]);
  const { Option } = Select;

  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchCompetitionsWinningsChartData(competitionValue?competitionValue:1,[1, 7, 14, 3, 12]);
    fetchCompetitionsData();
  }, [competitionValue]);

    /************************************************************/
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    const handleStartDateChange = (date) => {
      setStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
    };
  
    /************************************************************/

  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
    params: {
      fromDate: startDate ? startDate.format("YYYY-M-D") : "2024-01-01",
      toDate: endDate ? endDate.format("YYYY-M-D") : "2024-02-01",
    },
  }
  
  /************************************************************/

    const handleChange = (value) => {
      setCompetitionValue(value);
      //  fetchCompetitionsWinningsChartData(value,selectedTypes);
    }
    const handleFetchDataClick = () => {
       fetchCompetitionsWinningsChartData(competitionValue,selectedTypes);
    }

  /************************customStyles**********************************/
  const containerStyle = {
    marginBlockStart: "2rem",
    marginInline: "1rem",
  };

  const customColors = ["#7684af"];

  const customStateHeader = () => (
    <div style={{ paddingBlock: "1.5rem", background: "#e5eaf9" }}>
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
          paddingInlineStart: "1rem",
        }}
      >
        States
      </span>
    </div>
  );

  /**********************************Fetch Bar Chart Data*********************************************/

    const fetchCompetitionsWinningsChartData = async (competition, types) => {//get the competition id and types for the first time bu useEffect() 
      try {
        const promises = types.map(async (type) => {//here i make GET requests for each [type]/and return the data.
          const specificCompetitionsWinningApi = `${Host}:${Port}/admin/statistics/winner_for_specific_competitions?type=${type}&competition=${competition}`;
          const response = await Axios.get(specificCompetitionsWinningApi, headers);
          return response.data.data;
        });
  
        const chartDataArray = await Promise.all(promises);//here I use [Promise.all] to wait all Get requests for done.
        setCompetitionsWinningsLineChartData(chartDataArray);//then i set the data result in [competitionsWinningsLineChartData].
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
  /*************************************Fetch Bar Chart Data******************************************/
  /***************************************************************************************************/
  const fetchCompetitionsData = async () => {
    try {
      const competitionsApi =`${Host}:${Port}/admin/statistics/winner_for_competitions`;
      const response = await Axios.get(competitionsApi,headers);
  
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        setCompetitionsData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  /***************************************************************************************************/
  const colors = ['#8884d8'];
  const toolTipContentStyle={ display: 'flex',flexDirection: 'column',alignItems: 'flex-start',padding: '8px', backgroundColor: '#fff',color:'#8884D8', border: '1px solid #ccc'};
  const renderBarchart = () => {
    return (
      <Row gutter={[16, 16]}>
        {selectedTypes.map((type, index) => (
          <Col key={index} span={12}>
            <LineChart
              data={competitionsWinningsLineChartData[index]}
              xKey="event_time"
              yKey="predictions_success"
              tooltipContent={(value, payload) => (
                <div style={toolTipContentStyle}>
                  <p>Predictions Success: {value}</p>
                  <p>Number of Predictions: {payload.number_of_predictions}</p>
                  <p>Total wining: {((payload.predictions_success / payload.number_of_predictions) * 100).toFixed(1)}%</p>
                </div>
              )}
              colors={colors}
              chartTitle={type===1?t("menu.whoTheWinner"):type===3?t("menu.NumberOfGoalsInMatch"):type===7?t("menu.whoScoreFirst"):type===12?t("menu.bothTeamsWillScoreGoals"):type===14?t("menu.doubleChance"):''} // You can customize chart title dynamically
            />
          </Col>
        ))}
      </Row>
    );
  };
  const options = competitionsData?competitionsData.map((item) => (
    <Option value={item.competition}>{item.name}</Option>
  )):'';
  
  return (
    <div className={`statistic-container ${colorTheme==="dark"?"dark-mode":''}`}>
      <Layout dir={isLtr ? "ltr" : "rtl"}>
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />
        {customStateHeader()}
        <div style={containerStyle}>
          <div style={{ marginBlockStart: "1rem" }}>
            <Space>
            <Select defaultValue="تشامبيونشيب" style={{ width: 200 }} onChange={handleChange}>  
                  {options}

                {/* <OptGroup label="Engineer">
                  <Option value="Yiminghe">yiminghe</Option>
                </OptGroup> */}
            </Select>
              <DatePicker
                placeholder="Start Date"
                onChange={handleStartDateChange}
              />
              <DatePicker
                placeholder={t("button.EndDate")}
                onChange={handleEndDateChange}
              />
              <Button type="primary" disabled={!startDate || !endDate} onClick={handleFetchDataClick}>
              {t("formLabels.predictionsInfo.search")}
              </Button>
            </Space>
          </div>
          {renderBarchart()}
            
        </div>
      </Layout>
    </div>
  );
};

export default CompetitionsWinnings;
