import React, { useState, useEffect } from "react";
import { Host,Port} from '../Routes/apiConfig';
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import {  Table,Spin ,  DatePicker,  Button,  Modal,  Form,  message,  Input,  Space,Layout,Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import {SearchOutlined,EditOutlined,DeleteOutlined,QuestionCircleOutlined} from "@ant-design/icons";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../LayoutContext";
import MyHeader from "../compo/Header";
import './pages-css/internalUsers.css'
import { useTranslation } from "react-i18next";

const InternalUsers = ({colorTheme}) => {
  const { isLtr } = useLayout();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    const sessionKey = Cookies.get("sessionKeySwin");
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);
  /*********************************************************/
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }
  /*********************************************************/
  const fetchData = async () => {
    try {
      const internalUsersApi=`${Host}:${Port}/admin/users/`;
      setLoading(true);
      const response = await Axios.get(internalUsersApi,headers);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };
  /*********************************************************/
  const editRecord = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setVisible(true);
  };

  const deleteRecord = async (id) => {
    try {
      await Axios.delete("/api/records/delete", { data: { id } });
      fetchData();
    } catch (error) {
      console.error("Failed:", error);
    }
  };
  /*********************************************************/
  const handleOk = async (e) => {
    e.preventDefault();

    try {
      const values = await form.validateFields();

      if (editingRecord) {
        const updatedData = {
          id: editingRecord.id,
          user_name: values.user_name,
          password: values.password,
        };

        // PUT request for updating an existing record
        await Axios.put(`${Host}:${Port}/admin/users`, updatedData, {
          headers: {
            "hyazs-session-key": sessionKey,
            "HYAZS-API-KEY":
              "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
          },
        })
          .then((response) => {
            if (response.data.statusCode === 0) {
              setEditingRecord(null);
              message.success(response.data.message);
            } else {
              setEditingRecord(null);
              message.warning(response.data.message);
            }
            fetchData();
            setVisible(false);
            form.resetFields();
          })
          .catch((error) => {
            // Handle an error
            console.error("Update Error:", error);
          });
      } else {
        const newRecord = {
          user_name: values.userName,
          mobile: values.mobile,
          password: values.password,
        };

        // POST request for creating a new record
        await Axios.post(`${Host}:${Port}/admin/users`, newRecord, {
          headers: {
            "hyazs-session-key": sessionKey,
            "HYAZS-API-KEY":
              "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
          },
        })
          .then((response) => {
            // Handle a successful response
            if (response.data.statusCode === 0) {
              setEditingRecord(null);
              message.success(response.data.message);
            } else {
              setEditingRecord(null);
              message.warning(response.data.message);
            }
            fetchData();
            setVisible(false);
            form.resetFields();
          })
          .catch((error) => {
            // Handle an error
            console.error("Create Error:", error);
          });
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  /*********************************************************/
  const handleCancel = () => {
    setEditingRecord(null);
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // sorter: (a, b) => a.key - b.key,
      onFilter: (value, record) => record.id.toString().includes(value), // Use 'id' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },

    {
      title: t("formLabels.usersInfo.userName"),
      dataIndex: "user_name",
      key: "user_name",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.user_name.toLowerCase().includes(value.toLowerCase()),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.usersInfo.searchUserName")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.usersInfo.email"),
      dataIndex: "email",
      key: "email",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()), // Use 'email' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.usersInfo.searchEmail")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.usersInfo.mobile"),
      dataIndex: "phone",
      key: "phone",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) => record.phone?.toString().includes(value), // Use 'mobile' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.usersInfo.searchMobile")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.usersInfo.status"),
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.status.toLowerCase().includes(value.toLowerCase()), // Use 'status' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.usersInfo.searchStatus")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.usersInfo.password"),
      dataIndex: "password",
      key: "password",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button className="custom-edit-button" type="primary" icon={<EditOutlined />} onClick={() => editRecord(record)}>{t("table.edit")}</Button>
          <Button className="custom-delete-button" type="danger" icon={<DeleteOutlined />} disabled onClick={() => deleteRecord(record.id)}>
            {t("table.delete")}
          </Button>
        </Space>
      ),
    },
    // {
    //   title: "Delete",
    //   render: (record) => (
    //     <Popconfirm title="Sure to delete this user ?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => handleDelete(record)}>
    //         <Button className="custom-delete-button" type="danger" icon={<DeleteOutlined />}>{t("table.delete")}
    //       </Button>
    //       </Popconfirm>
    //   ),
    // },
  ];
  // const handleDelete = (record)=>{console.log("the record row is :",record)};
  const customTableHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      >
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
        }}
      >
        {t("menu.internal_users")}
      </span>
      <Button type="primary" onClick={() => setVisible(true)}>
        {t("table.add")}
      </Button>
    </div>
  );
  return (
    <Layout dir={isLtr ? "ltr" : "rtl"}>
      <div className={`modern-table-container ${colorTheme==="dark"?"dark-mode":''}`}>
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />

        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{ defaultPageSize: 10 }}
          onChange={fetchData}
          // expandable={{
          //   expandedRowRender: () => (
          //     <Button onClick={() => setVisible(true)}>Add Record</Button>
          //   ),
          // }}
          loading={loading}
          title={customTableHeader}
          indicator={
            <Spin
              tip="Loading..."
              size="large"
              style={{ display: "flex", justifyContent: "center" }}
            />
          }
          scroll={{ x: '100%' }}
        />
        <Modal
          title={editingRecord ? t("table.edit") : t("table.add")}
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="User name"
              name="user_name"
              rules={[
                {
                  required: true,
                  message: "Please enter a user name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {!editingRecord ? (
              <Form.Item
                label="Mobile"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter a mobile number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter a password!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Layout>
  );
};

export default InternalUsers;
