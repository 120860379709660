import React from 'react';
import { Image } from 'antd';
import LogoIcon from '../images/vertical-logo-withTitle.svg';
import '../compo-css/logo.css'
const Logo = () => {
  return (
    <div className='logo'>
      <div className="logo-icon">
      <Image
        // width={90}
        // height={90}
        src={LogoIcon}
        preview={false}
        alt="Logo Image"
      />
      </div>
    </div>
  );
}

export default Logo;
