import React, { useState, useEffect } from "react";
import { Host, Port } from "../../Routes/apiConfig";
import {
  Select,
  Menu,
  Table,
  DatePicker,
  Button,
  Modal,
  message,
  Form,
  Input,
  Space,
  Layout,
  Row,
  Col,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../../LayoutContext";
import moment from "moment";
import MyHeader from "../../compo/Header";
import Barchart from "../../Charts/BarChart";
import Linechart from "../../Charts/lineChart";
import Areachart from "../../Charts/AreaChart";
import Charts from "../../compo/Charts";

const DailySubscribers = () => {
  const { isLtr } = useLayout();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");

  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchDailySubscribersChartData();
  }, []);

  /************************************************************/
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFetchDataClick = () => {
    fetchDailySubscribersChartData();
  };
  /************************************************************/

  /************************customStyles**********************************/
  const containerStyle = {
    marginBlockStart: "2rem",
    marginInline: "1rem",
  };

  const customColors = ["#7684af"];

  const navLinkStyle = {
    textDecoration: "none",
    padding: "8px 16px",
    margin: "4px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    color: "#333",
    backgroundColor: "#f4f4f4",
    transition: "background-color 0.2s",
  };

  const customStateHeader = () => (
    <div style={{ paddingBlock: "1.5rem", background: "#e5eaf9" }}>
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
          paddingInlineStart: "1rem",
        }}
      >
        States
      </span>
    </div>
  );

  const [subscribersDailyBarChartData, setDailyBallsBarChartData] = useState([]);

  /**********************************Fetch Bar Chart Data*********************************************/

  const fetchDailySubscribersChartData = async () => {
    try {
      const response = await Axios.get(
        `${Host}:${Port}/admin/state_subscriber_verify_per_day`,
        {
          params: {
            startTime: startDate ? startDate.format("M-D-YYYY") : "1-1-2024",
            endTime: endDate ? endDate.format("M-D-YYYY") : "2-1-2024",
          },
          headers: {
            "hyazs-session-key": sessionKey,
            "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
          },
        }
      );
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else setDailyBallsBarChartData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    console.log("START DATE",startDate);
    console.log("END DATE",endDate);
  };
  /*************************************Fetch Bar Chart Data******************************************/

  /***************************************************************************************************/
  const datakeysBars = ['Registered'];
  const colors = ['#8884d8'];

  const renderBarchart = () => {
    return (
      <div > 
        <Row justify="center" align="middle">
          <Col span={50}>
            <Barchart
              data={subscribersDailyBarChartData}
              xKey="days"
              yKey="Registered"
              colors={colors}
              chartTitle="Registered"
              width={800} // or any desired width value
              height={500} // or any desired height value
              barNumber={1}
              datakeysBarsValues={datakeysBars}
            />
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div>
      <Layout dir={isLtr ? "ltr" : "rtl"}>
        <MyHeader
          DrawerTitle="This is the drawer title"
          DrawerFooter="This is the drawer footer"
        />
        {customStateHeader()}
        <div style={containerStyle}>
          <div style={{ marginBlockStart: "1rem" }}>
            <Space>
              <DatePicker
                placeholder="Start Date"
                onChange={handleStartDateChange}
              />
              <DatePicker
                placeholder="End Date"
                onChange={handleEndDateChange}
              />
              <Button type="primary" onClick={handleFetchDataClick}>
                Fetch Data
              </Button>
            </Space>
          </div>
          {renderBarchart()}
        </div>
      </Layout>
    </div>
  );
};

export default DailySubscribers;
