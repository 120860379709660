import React, { useState, useEffect } from "react";
import { Host,Port} from '../Routes/apiConfig';
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import {Table,DatePicker,Button,Modal,Form,message,Input,Space,Layout,Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {SearchOutlined,EditOutlined,DeleteOutlined,QuestionCircleOutlined} from "@ant-design/icons";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../../src/LayoutContext";
import MyHeader from "../compo/Header";
import { useTranslation } from "react-i18next";
import './pages-css/subscribers.css'
import Dashboard from '../pages/Dashboard'
import { useUserRole } from '../UserRoleContext';
const storedUserRole = localStorage.getItem('userRole');
const Subscribers = ({colorTheme}) => {
  const { userRole } = useUserRole();
  const { isLtr } = useLayout();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    const sessionKey = Cookies.get("sessionKeySwin");
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);
  /*********************************************************/
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }
  /*********************************************************/
  const fetchData = async () => {
    try {
      setLoading(true);
      const subscribersApi=`${Host}:${Port}/admin/subscribers`;

      const response = await Axios.get(subscribersApi,headers);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        setData(response.data.data);
        console.log("the response Data:",response);
        // await fetchDataaa(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };
  /*********************************************************/
  const editRecord = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setVisible(true);
  };

  const deleteRecord = async (id) => {
    try {
      await Axios.delete("/api/records/delete", { data: { id } });
      fetchData();
    } catch (error) {
      console.error("Failed:", error);
    }
  };
  /*********************************************************/
  const handleOk = async (e) => {
    e.preventDefault();
  
    try {
      const values = await form.validateFields();
  
      if (editingRecord) {
        const updatedData = {
          id: editingRecord.id,
          mobile: values.mobile,
          password: values.password,
        };
  
        await Axios.put(
          `${Host}:${Port}/admin/subscribers`,
          updatedData,
          {
            headers: {
              "hyazs-session-key": sessionKey,
              "HYAZS-API-KEY":
                "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
            },
          }
        )
          .then((response) => {
            if (response.data.statusCode === 0) {
              message.warning('Your custom warning message goes here');
            } else {
              message.success('Update successful'); // Show a success message for a successful update
            }
            fetchData();
          })
          .catch((error) => {
            // Handle an error
            console.error("Update Error:", error);
          });
      }
      fetchData();
      setVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Failed:", error);
    }
  };


  /*********************************************************/
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    fetchData();
  }, []);
  
/*******************Subscriber-Info-button************************/
const navigateToWithData = (data) => {
  localStorage.setItem('userId', data.id);
  navigate(`/userInfo`);
};
/********************End_Subscriber-Info-button***********************/

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // sorter: (a, b) => a.key - b.key,
      onFilter: (value, record) => record.id.toString().includes(value), // Use 'id' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },

    {
      title: t("formLabels.subscribersInfo.subscriberName"),
      dataIndex: "name",
      key: "name",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()), // Use 'email' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.subscribersInfo.searchSubscriberName")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.subscribersInfo.email"),
      dataIndex: "email",
      key: "email",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.email ? record.email.toLowerCase().includes(value.toLowerCase()) : false, // Use 'email' here and check for null
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.subscribersInfo.searchEmail")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.subscribersInfo.mobile"),
      dataIndex: "mobile",
      key: "mobile",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) => record.mobile?.toString().includes(value), // Use 'mobile' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.subscribersInfo.searchMobile")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.subscribersInfo.predictions_demo"),
      dataIndex: ["points", "predictions_demo"],
      key: "predictions_demo",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.points.predictions_demo.toString().includes(value), // Use the correct path to 'predictions_demo'
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.subscribersInfo.searchPredictionsDemo")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: t("formLabels.subscribersInfo.status"),
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.status.toLowerCase().includes(value.toLowerCase()), // Use 'status' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.subscribersInfo.searchStatus")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: '18px' }} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
           {(userRole === "admin") && (
            <>
          <Button className="custom-edit-button" type="primary" icon={<EditOutlined />} onClick={() => editRecord(record)}>{t("table.edit")}</Button>
          <Button className="custom-delete-button" type="danger" icon={<DeleteOutlined />} disabled onClick={() => deleteRecord(record.id)}>
            {t("table.delete")}
          </Button>
          </>)}
          <Tooltip title="subscriber Info" color={"purple"}>
            <Button className="custom-link-button" type="primary" icon={<QuestionCircleOutlined />} onClick={() => navigateToWithData(record)}>
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const customTableHeader = () => (
    <div>
      <span style={{
        background: 'linear-gradient(to right, #7684af, #21daff)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontSize: '28px',
        fontWeight: 'bold',
      }}>
  {t("menu.subscribers")}
</span>
      {/* <Button type="primary" onClick={() => setVisible(true)}>
        {t("table.add")}
      </Button> */}
    </div>
  );
  return (
    <Layout dir={isLtr ? "ltr" : "rtl"}>
      <div className={`modern-table-container ${colorTheme==="dark"?"dark-mode":''}`}>
      
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />
        
        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{ defaultPageSize: 10 }}
          onChange={fetchData}
          // expandable={{
          //   expandedRowRender: () => (
          //     <Button onClick={() => setVisible(true)}>Add Record</Button>
          //   ),
          // }}
          title={customTableHeader}
          loading={loading}
          scroll={{ x: '100%' }}
        />
        <Modal
          title={editingRecord ? t("table.edit") : t("table.add")}
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            {!editingRecord ? 
              <Form.Item
                label="User name"
                name="userName"
                rules={[
                  {
                    required: true,
                    message: "Please enter a user name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
             : "" }
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter a mobile number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter a password!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Layout>
  );
};

export default Subscribers;
