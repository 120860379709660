import React, { useState, useRef, useEffect } from "react";
import { Host, Port } from "../Routes/apiConfig";
import { Button, Drawer, Menu, Layout, Image } from "antd";
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate
import Cookies from "js-cookie";
import { MenuOutlined } from "@ant-design/icons";
import Menus from "../pages/Menus2";
import "./header.css";
import image from "../images/horizontal-logo-withTitle.svg";
import { useLayout } from "../LayoutContext";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

const MyHeader = ({HeadercolorTheme}) => {
  const { isLtr, toggleLayoutDirection } = useLayout();
  console.log("the Header direction is :", isLtr);
  const { t, i18n } = useTranslation();

  /*****************************************************************/
  const headerStyle = {
    backgroundColor: `${HeadercolorTheme==="dark"?"#001529":"#f4f4f4"}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  };

  const logoStyle = {
    flex: 1, // Takes up remaining space
  };

  return (
    <Layout className="my-Header" dir={isLtr ? "ltr" : "rtl"}>
      <div>
        <Header style={headerStyle}>
          <div style={logoStyle}>
            <Image src={image} alt="Logo" height={64} preview={false} />
          </div>
          <Button type="primary" onClick={toggleLayoutDirection}>
            {isLtr ? "العربية" : "English"}
          </Button>
        </Header>

      </div>
    </Layout>
  );
};

export default MyHeader;
