import React, { createContext, useContext, useState } from "react";
import i18n from "i18next";

export const LayoutContext = createContext();

// export const useLayout = () => {
//   return useContext(LayoutContext);
// };
export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};

export const LayoutProvider = ({ children }) => {
  const [isLtr, setisLtr] = useState(true); // Initial state set to false

  const toggleLayoutDirection = () => {
    setisLtr((prevIsLtr) => {
      const newIsLtr = !prevIsLtr;
      const newLanguage = newIsLtr ? "en" : "ar";
      i18n.changeLanguage(newLanguage);
      return newIsLtr;
    });
  };

  

  return (
    <LayoutContext.Provider value={{ isLtr, toggleLayoutDirection }}>
      {children}
    </LayoutContext.Provider>
  );
};

