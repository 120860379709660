import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useLayout } from "../../src/LayoutContext";
import { Layout } from "antd";

const Linechart = ({ data, xKey,yKey, colors, chartTitle,tooltipContent,contentStyle,activeDot,lineType,animationDuration}) => {
  const { isLtr } = useLayout();

  return (
    <Layout  dir={isLtr ? "ltr" : "rtl"}>
    <div className="chart-container">
      <h2>{chartTitle}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="1" />
          <XAxis dataKey={xKey}  orientation="bottom" angle={0} textAnchor="middle" padding={{ left: 5, right: 0 }}/>
          <YAxis dataKey={yKey} tick={isLtr?{ dx: -7 }:{ dx: -25 }}/>
          <Tooltip animationEasing="ease-in" contentStyle={contentStyle} content={({ active, payload, label }) =>
              active && payload && payload.length ? tooltipContent(payload[0].value, payload[0].payload) : null} 
          />
          <Legend verticalAlign="top" align="right" formatter={(value) => value.replace(/_/g, ' ')}/>
          {colors.map((color, index) => (
            <Line
              key={yKey}
              dataKey={yKey}
              stroke={color}
              type={lineType?lineType:"monotone"}
              animationDuration={animationDuration?animationDuration:1000}
              dot={{ stroke: color, strokeWidth: 3 }}
              activeDot={{ r: activeDot?activeDot:4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
    </Layout>
  );
};

export default Linechart;
