import React, { useState, useEffect } from "react";
import { Host, Port } from "../../Routes/apiConfig";
import {Pagination,Card,Select,Menu,Table,DatePicker,Button,Flex,Modal,Dropdown,message,Form,Input,Space,Layout,Row,Col,Descriptions,Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../../LayoutContext";
import moment from "moment";
import MyHeader from "../../compo/Header";
import LineChart from "../../Charts/lineChart";
import Barchart from "../../Charts/BarChart";
import DataDisplay from '../states/UserInfoDisplay';

import '../pages-css/states.css'
const SubscribersDailyInfo = ({colorTheme}) => {
  const { t, i18n } = useTranslation();
  const { isLtr } = useLayout();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  const { Option } = Select;
  /***************************************************/
  // const defaultStartDate = moment().subtract(7, 'days');
  // const defaultEndDate = moment();
  
  const [startTime, setStartDate] = useState();
  const [endTime, setEndDate] = useState();
  const [subscribersDailyInfoChartData, setSubscribersDailyInfoChartData] = useState([]);
  const [subscribersDailyInfoTotalData, setSubscribersDailyInfoTotalChartData] = useState([]);
  const [tableLoading, setTableLoading] = useState(); // Loading state

  /***************************************************/
  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchSubscribersDailyInfoChartData();
  },[]); 

    /************************************************************/

    const handleStartDateChange = (date) => {
      
      setStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
    };
    const handleFetchDataClick = () => {
      fetchSubscribersDailyInfoChartData();
    };

  
    /************************************************************/

  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
    params: {
      startTime: startTime ? startTime.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      endTime: endTime? endTime.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    },
  }
  const subscriberCountriesHeaders ={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }
  /************************************************************/


  /************************customStyles**********************************/
  const containerStyle = {
    marginBlockStart: "2rem",
    marginInline: "1rem",
  };

  const customColors = ["#7684af"];

  const customStateHeader = () => (
    <div style={{ paddingBlock: "1.5rem", background: "#e5eaf9" }}>
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
          paddingInlineStart: "1rem",
        }}
      >
        States
      </span>
    </div>
  );

  /**********************************Fetch Bar Chart Data*********************************************/

    const fetchSubscribersDailyInfoChartData = async (customDateRange) => {
      setTableLoading(true);
      if(customDateRange){
        const {params}=headers;
        params.startTime=customDateRange; // set start time to today
        params.endTime=moment().format("YYYY-MM-DD");
      }
      try {
        const subscribersPerDayApi = `${Host}:${Port}/admin/statistics/daily_system`;
        const response = await Axios.get(subscribersPerDayApi, headers);

        if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
          message.error(response.data.message);
        } else if (response.data.statusCode === -101) {
          Cookies.remove("sessionKeySwin");
          navigate("/login");
        } else {
          setSubscribersDailyInfoChartData(response.data.data.data!== null? response.data.data.filter((item) => item.create_time !== null):[]);
          setSubscribersDailyInfoTotalChartData(response.data.data.filter((item) => item.create_time === null));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally{
        setTableLoading(false);
      }
    };
    
  /*************************************End- Fetch Bar Chart Data******************************************/
  /***************************************************************************************************/
  const fetchSubscribersCountriesData = async (specificDate,dateRange) => {    
    try {
      const subscribersCountriesApi = 
      dateRange?`${Host}:${Port}/admin/subscribers/info?sortBy=country&fromDate=${subscribersDailyInfoChartData[0].create_time}&toDate=${subscribersDailyInfoChartData.slice(-1)[0].create_time}`:
      `${Host}:${Port}/admin/subscribers/info?sortBy=country&specificDate=${specificDate}`;
      const response = await Axios.get(subscribersCountriesApi, subscriberCountriesHeaders);

      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        await subscribersCountriesModel(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick =async (data) => {
  await fetchSubscribersCountriesData(data.create_time);
  }

  
  const subscribersCountriesColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => {
        return value !== null ? value : "No Specific Country";
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      render: (value) => {
        return value !== null ? value : "none";
      },
    }
  ];
  const handleTotalRegisteredButtonClick = (x,dateRange)=> {fetchSubscribersCountriesData(x,dateRange)}

  const subscribersCountriesModel = async (data) => {
  Modal.info({
        title: "Subscribers Countries",
        content: (
          <div>
            <Table
              className="injected_table"
              dataSource={data}
              columns={subscribersCountriesColumns}
              scroll={{ x: '100%' }}
              pagination={{ defaultPageSize: 4 }}
            />
          </div>
        ),
        width: "70%",
        onOk() {},
      })
  };

  const columns = [

    {
      title: t("formLabels.predictionsInfo.createTime"),
      dataIndex: "create_time",
      key: "create_time",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: t("formLabels.predictionsInfo.registered"),
      dataIndex: "profiles_count",
      key: "profiles_count",
      render: (registered, rowData) => (
        <Tooltip placement="topLeft" title={`Subscribers Countries On ${rowData.create_time}`} color={"#49557d"}>
              <Button
                type="link"
                onClick={() => handleRowClick(rowData)}
              >
                {registered? registered : 'No Data'}
              </Button>
        </Tooltip>
      ),
    },
    {
      title: t("formLabels.predictionsInfo.predictionsCount"),
      dataIndex: "predictions_count",
      key: "predictions_count",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: t("formLabels.predictionsInfo.packagePredictionsCount"),
      dataIndex: "package_predictions_count",
      key: "package_predictions_count",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: t("formLabels.predictionsInfo.distinctLoginsPredictions"),
      dataIndex: "distinct_logins_predictions",
      key: "distinct_logins_predictions",
      render: (value) => {
        return value !== null ? value : "none";
      },
    },
    {
      title: t("formLabels.predictionsInfo.distinctLoginsPackagePredictions"),
      dataIndex: "distinct_logins_package_predictions",
      key: "distinct_logins_package_predictions",
      render: (value) => {
        return value !== null ? value : "none";
      },
          
    },
  ];
  /***************************************************************************************************/
  const options=[
    {
      value: 'today',
      label: t("formLabels.predictionsInfo.today"),
    },
    {
      value: 'last3days',
      label: t("formLabels.predictionsInfo.lastThreeDays"),
    },
    {
      value: 'lastWeek',
      label: t("formLabels.predictionsInfo.lastWeek"),
    },
    {
      value: 'lastMonth',
      label: t("formLabels.predictionsInfo.lastMonth"),
    },
  ]

  const handleDateSelectChange = (selected) => {
    console.log("The Date Selected Value",selected.value);
    switch (selected.value) {
      case 'today':
        fetchSubscribersDailyInfoChartData(moment().format("YYYY-MM-DD"));
        console.log("Today",moment().format("YYYY-MM-DD"))
        break;
      case 'last3days':
        fetchSubscribersDailyInfoChartData(moment().subtract(3, 'days').format("YYYY-MM-DD"));
        break;
      case 'lastWeek':
        fetchSubscribersDailyInfoChartData(moment().subtract(1, 'week').format("YYYY-MM-DD"));
        console.log("last week",moment().subtract(1, 'week').format("YYYY-MM-DD"))
        break;
      case 'lastMonth':
        fetchSubscribersDailyInfoChartData(moment().subtract(1, 'month').format("YYYY-MM-DD"));
        console.log("last month",moment().subtract(1, 'month').format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
  };
  /***************************************************************************************************/
  const colors = ['#8884d8'];
  const toolTipContentStyle={ display: 'flex',flexDirection: 'column',alignItems: 'flex-start',padding: '8px', backgroundColor: '#fff',color:'#8884D8', border: '1px solid #ccc'};
  const keys = subscribersDailyInfoChartData && subscribersDailyInfoChartData.length > 0 ? Object.keys(subscribersDailyInfoChartData[0]).filter(key => key !== 'create_time') : [];
  const transformKeyName = (key) => {
    return key.replace(/_/g, ' ');
  };
  const renderBarchart = () => {
    return (
      <Row gutter={[16, 16]}>
        {keys.map((key, index) => (
          <Col key={index} span={12}>
            <LineChart
              data={subscribersDailyInfoChartData || []}
              xKey="create_time"
              yKey={key}
              tooltipContent={(value, payload) => (
                <div style={toolTipContentStyle}>
                  <p>{t("formLabels.predictionsInfo.createTime")}: {payload.create_time}</p>
                  <p>{
                key ==="profiles_count"?t("formLabels.predictionsInfo.registered"):
                key==="predictions_count"?t("formLabels.predictionsInfo.predictionsCount"):
                key==="package_predictions_count"?t("formLabels.predictionsInfo.packagePredictionsCount"):
                key==="distinct_logins_predictions"?t("formLabels.predictionsInfo.distinctLoginsPredictions"):
                key==="distinct_logins_package_predictions"?t("formLabels.predictionsInfo.distinctLoginsPackagePredictions"):''} : {value}</p>
                </div>
              )}
              colors={colors}
              chartTitle={
                key ==="profiles_count"?t("formLabels.predictionsInfo.registered"):
                key==="predictions_count"?t("formLabels.predictionsInfo.predictionsCount"):
                key==="package_predictions_count"?t("formLabels.predictionsInfo.packagePredictionsCount"):
                key==="distinct_logins_predictions"?t("formLabels.predictionsInfo.distinctLoginsPredictions"):
                key==="distinct_logins_package_predictions"?t("formLabels.predictionsInfo.distinctLoginsPackagePredictions"):''
              } // You can customize chart title dynamically
              activeDot={8}
            />
          </Col>
        ))}
      </Row>
    );
  };  
  const tableFooter = () => {
    if (!subscribersDailyInfoTotalData || !subscribersDailyInfoTotalData.length) {
      return null; // No data to display in the footer
    }
  
    
    const {
      profiles_count,
      predictions_count,
      package_predictions_count,
      distinct_logins_predictions,
      distinct_logins_package_predictions
    } = subscribersDailyInfoTotalData[0];

    const itemsOfTotalInfo = [
      {
        key: '1',
        label: t("formLabels.predictionsInfo.totalRegistered"),
        children: (
          <div style={{ textAlign: 'center' }}>
          <Tooltip placement="topLeft" title={
            <span>
              Subscribers Countries<br/>
              from {subscribersDailyInfoChartData[0].create_time}<br/>
              to {subscribersDailyInfoChartData.slice(-1)[0].create_time}
            </span>
          }  color={"#49557d"}>
                <Button
                  type="link"
                  onClick={() => handleTotalRegisteredButtonClick("handleTotalRegisteredButtonClick", true)}
                >
                  {profiles_count? profiles_count : 'No Data'}
                </Button>
          </Tooltip>
          </div>
        ),
      },
      {
        key: '2',
        label: t("formLabels.predictionsInfo.totalPredictions"),
        children: predictions_count,
      },
      {
        key: '3',
        label: t("formLabels.predictionsInfo.totalPredictionsPackages"),
        children: package_predictions_count,
      },
      {
        key: '4',
        label: t("formLabels.predictionsInfo.totalOfPredictors"),
        children: distinct_logins_predictions,
      },
      {
        key: '5',
        label: t("formLabels.predictionsInfo.totalOfPackagesPredictors"),
        children: distinct_logins_package_predictions,
      }
    ];
    return (
      <DataDisplay title={""} items={itemsOfTotalInfo} column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} size={"small"} bordered={true}/>
    );
  };
  return (
    <div className={`statistic-container ${colorTheme==="dark"?"dark-mode":''}`}>
      <Layout dir={isLtr ? "ltr" : "rtl"}>
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />
        {customStateHeader()}
        <div style={containerStyle}>
          <div style={{ marginBlockStart: "1rem" }}>
            <Space>
              <Select
                labelInValue
                defaultValue={{
                  value: 'today',
                }}
                style={{
                  width: 150,
                }}
                onChange={handleDateSelectChange}
                options={options}
                disabled={startTime || endTime}
              />
              <DatePicker
                placeholder={t("button.startDate")}
                onChange={handleStartDateChange}
              />
              <DatePicker
                placeholder={t("button.EndDate")}
                onChange={handleEndDateChange}
              />
              <Button type="primary" disabled={!startTime || !endTime} onClick={handleFetchDataClick}>
              {t("formLabels.predictionsInfo.search")}
              </Button>
            </Space>
          </div>
          <>
          <Table
          className="injected_table"
          columns={columns}
          dataSource={subscribersDailyInfoChartData!==null?subscribersDailyInfoChartData:[]}
          rowKey="create time"
          onChange={''}
          title={""}
          footer={tableFooter}
          loading={tableLoading}
          pagination={{ defaultPageSize: 5 }}
          scroll={{ x: '100%' }}
        />
          </>
          {renderBarchart()}
            
        </div>
      </Layout>
    </div>
  );
};

export default SubscribersDailyInfo;
