import React, { useState, useEffect, useMemo } from 'react';
import { Host, Port } from '../Routes/apiConfig';
import { Button, Layout, Space, Typography, Segmented, Select, message, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import './pages-css/DragInterface.css';
import { useLayout } from "../../src/LayoutContext";
import Confetti from 'react-confetti';

const { Option } = Select;

const DragInterface = () => {
  const { isLtr } = useLayout();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  const [shuffling, setShuffling] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [subscriberId, setSubscriberId] = useState({});
  const [defaultSegmentedValue, setDefaultSegmentedValue] = useState('Daily');
  const [speed, setSpeed] = useState(120);
  const [loading, setLoading] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [users, setUsers] = useState([]);
  const [showCongrats, setShowCongrats] = useState(false); // New state for showing congrats modal

  const headers = {
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }

  useEffect(() => {
    const getCompetitions = async () => {
      try {
        setLoading(true);
        const drawCompetitionsApi = `${Host}:${Port}/draw/competitions`;
        const response = await Axios.get(drawCompetitionsApi, headers);
        if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
          message.error(response.data.message);
        } else if (response.data.statusCode === -101) {
          Cookies.remove("sessionKeySwin");
          navigate("/login");
        } else setCompetitions(response.data.data.competitions);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };
    getCompetitions();
  }, []);


  const getCompetitionsByIdUsers = async () => {
    try {
      const getCompetitionsByIdUsersApi = `${Host}:${Port}/draw/users_for_draw?competition=${selectedCompetition}`;
      const response = await Axios.get(getCompetitionsByIdUsersApi, headers);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        setUsers(response.data.data.users);
        if (response.data.data.users.length === 0) {
          message.info("لايوجد لاعبين على هذه البطولة");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedCompetition) {
      console.log("the competions id is :", selectedCompetition)
      
      getCompetitionsByIdUsers();
    }
  }, [selectedCompetition]);

  useEffect(() => {
    let interval;
    if (shuffling) {
      interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * users.length);
        setCurrentName(users[randomIndex].name);
      }, speed);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [shuffling, speed, users]);

  const startShuffle = () => {
    setShuffling(true);
  };

  const stopShuffle = async () => {
    setShuffling(false);
    const selectedUser = users.find(user => user.name === currentName);
    if (selectedUser) {
      setSubscriberId({ id: selectedUser.id, name: selectedUser.name });
      await gift(selectedUser.id);
      setShowCongrats(true); // Show congrats modal
    }
  };

  const gift = async (userId) => {
    try {
      console.log("THE USER ID IS ", userId)
      const giftApi = `${Host}:${Port}/draw/private_codes`;
      const response = await Axios.post(giftApi, { user_id: userId }, headers);

      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        message.success("Gift sent successfully!");
      }
    } catch (error) {
      console.error("Error sending gift:", error);
      message.error("Failed to send gift.");
    }
  };


  return (
    <Layout className="custom-layout" dir={isLtr ? "ltr" : "rtl"}>
      <div className='xx' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Space direction="horizontal" style={{ alignItems: 'center' }}>
          <Select
            style={{ textAlign: 'center', width: 400, marginBottom: 20,height:'40px',fontSize:'medium' }}
            placeholder="إختيار البطولة"
            onChange={setSelectedCompetition}
            dropdownStyle={{ textAlign: 'center', backgroundColor: '#7684af', color: '#ffff' }}
            >
            {competitions
            .filter(comp => comp.id !== 1)
            .map(comp => (
              <Option key={comp.id} value={comp.id}>{comp.name}</Option>
            ))}
          </Select>
          {/* <div className="segmented-container" style={{ background: "#7684af", borderRadius: '10px', padding: '10px' }}>
            <Segmented
              style={{ background: 'transparent' }}
              options={['Weekly']}
              defaultValue="Weekly"
              onChange={(value) => {
                setDefaultSegmentedValue(value);
              }}
            />
          </div> */}
        </Space>
        <div className="drag-interface" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '400px', height: '400px' }}>
          <Typography.Title style={{ color: '#bcf1d5' }} level={3}>واجهة السحب العشوائي</Typography.Title>
          <div className="name-box" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <div className={`name ${shuffling ? 'shuffling' : ''}`} style={{ animationDuration: `${speed}ms` }}>
              {currentName}
            </div>
          </div>
          <Space direction="horizontal">
            <Button type="primary" onClick={startShuffle} disabled={shuffling || users.length === 0} size="large" className="shuffle-button">
              بدء السحب
            </Button>
            <Button type="danger" onClick={stopShuffle} disabled={!shuffling || users.length === 0} size="large" className="shuffle-button">
              إيقاف
            </Button>
          </Space>
        </div>
        {showCongrats &&<Confetti style={{ zIndex: 9999 }}/>}
      </div>
      <Modal
        className='congrats-model'
        style={{textAlign:'center'}}
        width={730}
        open={showCongrats}
        onCancel={() => setShowCongrats(false)}
        footer={null}
        centered
        >
        <Typography.Title level={1} style={{ color: '#fff' }}>! {subscriberId.name} ألف مبروك للرابح</Typography.Title>
        <Typography.Title level={3} style={{ color: '#fff' }}>! لقد فزت بالجائزة</Typography.Title>
      </Modal>
    </Layout>
  );
};

export default DragInterface;