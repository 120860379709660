import React, { useState, useEffect } from "react";
import { Host, Port } from "../Routes/apiConfig";
import { uploadHost, uploadPort } from "../Routes/UploadConfig";
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import {
  Table,
  Spin,
  DatePicker,
  Button,
  Modal,
  Form,
  message,
  Input,
  Space,
  Layout,
  Upload,
  Select,
  Flex 
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import moment from "moment";
import Axios from "axios";
import { useLayout } from "../LayoutContext";
import MyHeader from "../compo/Header";
// import './pages-css/Games.css'
import { useTranslation } from "react-i18next";

const Games = ({colorTheme}) => {

  const { isLtr } = useLayout();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    const sessionKey = Cookies.get("sessionKeySwin");
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);
  const headers={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  }
  /*********************************************************/
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  /*********************************************************/
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  /**********************************************************/
  const fetchData = async (offSet, pageSize,date) => {
    console.log("The limit is :", pageSize, "amd the offset is:", offSet);
    try {
      setLoading(true);
      const gamesApi=`${Host}:${Port}/admin/games?limit=${pageSize ? pageSize : 10}&offset=${offSet ? offSet : 1}&withTotalCount=true`;
      const gamesApiDateFiltered=`${Host}:${Port}/admin/games?limit=${pageSize ? pageSize : 10}&offset=${offSet ? offSet : 1}&withTotalCount=true&${date?`startTime=${date}`:''}`;

      const response = date? await Axios.get(gamesApiDateFiltered,headers): await Axios.get(gamesApi,headers);

      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        setData(response.data.data.data);
        setTotalItems(response.data.data.totalCount);
        console.log("the Games Data is :",response.data.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };
  /*********************************************************/
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [uploadGameId, setUploadGameId] = useState(null); // New state to store the game ID for upload
  const [videoNotUploaded, setVideoNotUploaded] = useState(true); // New state to store the game ID for upload
  const [rowData, setRowData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);


  const openUploadModal = (gameId,data) => {
    setUploadGameId(gameId);
    setRowData(data);
    setUploadModalVisible(true);
  };

  const closeUploadModal = () => {
    setUploadModalVisible(false);
    setVideoNotUploaded(true);
    form.resetFields(); // Reset form fields
  };

  /*********************************************************/

  const uploadVideo = async (values, id) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("gameId", id);
      formData.append("competitor", values.competitor);
      formData.append("goalNumber", values.goalNumber);
      formData.append("file", values.video.file.originFileObj);
  
      // POST request for uploading video
      const response = await Axios.post(`${uploadHost}:${uploadPort}/upload`, formData);
  
      console.log("THE DATAAAA", formData, "the video is :", values.video.file.originFileObj);
  
      if (response.data === "تم تحميل الملف بنجاح!") {
        message.success("تم تحميل الملف بنجاح!");
        closeUploadModal();
        fetchData(); // Fetch updated data after successful upload
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Upload Error:", error);
    }finally {
      setLoading(false); // Set loading to false when upload is completed or encounters an error
    }
  };
  
  const handleVideoUpload = (info) => {
    console.log('handleVideoUpload called with status:', info.file.status,'the file object is :',info.file);
    if (info.file.status === 'uploading' || info.file.status === 'removed') {    
      setVideoNotUploaded(true);
    } else {
  setVideoNotUploaded(false);
  }
  }

  const maxSize = 10 * 1024 * 1024; // 10MB (adjust as needed)

  const beforeUpload = (file) => {
    if (file.size > maxSize) {
      // message.error(`File size must be within ${maxSize / (1024 * 1024)}MB!`);
      message.warning(`Video size is too large !`);
      return false;
    }
    return true;
  };
  /*********************************************************/

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    
    // Calculate the new offset based on the page and limit
    const newOffset = (page - 1) * pageSize + 1;
    
    fetchData(newOffset, pageSize, selectedDate)
    console.log("The page number now is :", page,"and the OFFSET IS:",newOffset,"and the PAGE SIZE IS:",pageSize);
    console.log("TOTAL:",totalItems)
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // sorter: (a, b) => a.key - b.key,
      onFilter: (value, record) => record.id.toString().includes(value), // Use 'id' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },

    {
      title: t("formLabels.gamesInfo.away"),
      dataIndex: "away",
      key: "away",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.away.toLowerCase().includes(value.toLowerCase()),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.gamesInfo.search_away")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.home"),
      dataIndex: "home",
      key: "home",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) =>
        record.home.toLowerCase().includes(value.toLowerCase()), // Use 'email' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.gamesInfo.search_home")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: t("formLabels.gamesInfo.season_num"),
      dataIndex: "season_num",
      key: "season_num",
      render: (value) => {
        return value !== null ? value : "none";
      },
      // sorter: (a, b) => a.value - b.value,
      onFilter: (value, record) => record.phone?.toString().includes(value), // Use 'mobile' here
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("formLabels.gamesInfo.search_season_num")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },

    {
      title: t("formLabels.gamesInfo.start_time"),
      dataIndex: "start_time",
      key: "start_time",
      render: (value) => {
        return value !== null
          ? moment(value).format("YYYY-MM-DD HH:mm:ss")
          : "none";
      },
      // onFilter: (value, record) => {
      //   const filterDate = moment(value).startOf("day");
      //   const recordDate = moment(record.start_time).startOf("day");
      //   return filterDate.isSame(recordDate);
      // },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            placeholder={t("formLabels.gamesInfo.start_time")}
            onChange={(date) =>{
              setSelectedDate(date.format("MM-DD-YYYY"));
            }}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {confirm()
              if(selectedDate){
                fetchData(null,null,selectedDate);
              }
              }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
          onClick={() => {
            clearFilters();
            setSelectedDate(null);
            fetchData();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
</Button>
        </div>
      ),
      filterIcon: <SearchOutlined style={{ fontSize: "18px" }} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="custom-upload-button"
            type="primary"
            onClick={() => openUploadModal(record.id,record)}
          >
            {t("formLabels.gamesInfo.gamesDetails.upload_video")}
          </Button>
        </Space>
      ),
    },
  ];
  const customTableHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
        }}
      >
        {t("menu.games")}
      </span>
    </div>
  );
  return (
    <Layout dir={isLtr ? "ltr" : "rtl"}>
      {/* <Flex wrap gap="small" vertical> */}
      <div className={`modern-table-container ${colorTheme==="dark"?"dark-mode":''}`}>
        <MyHeader
          // DrawerTitle="This is the drawer title"
          // DrawerFooter="This is the drawer footer"
          HeadercolorTheme={colorTheme}
        />

        <Table
          className="custom-table"
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalItems,
            onChange: handlePageChange,
          }}
          loading={loading}
          title={customTableHeader}
          indicator={
            <Spin
              tip="Loading..."
              size="large"
              style={{ display: "flex", justifyContent: "center" }}
            />
          }
          scroll={{ x: '100%' }}
        />
        <Modal
          title="Upload Video"
          open={uploadModalVisible}
          onCancel={closeUploadModal}
          footer={null}
        >
          <Form
            form={form} // Pass the form instance
            layout="vertical"
            onFinish={(values) => uploadVideo(values, uploadGameId)}
          >
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.competitor")}
              name="competitor"
              rules={[
                {
                  required: true,
                  message: "Please select a competitor!",
                },
              ]}
            >
              <Select>
                {rowData && rowData.home && (
                  <Select.Option value={rowData.home}>{rowData.home}</Select.Option>
                )}
                {rowData && rowData.away && (
                  <Select.Option value={rowData.away}>{rowData.away}</Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.goal_number")}
              name="goalNumber"
              rules={[
                {
                  required: true,
                  message: "Please insert a goalNumber!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("formLabels.gamesInfo.gamesDetails.video")}
              name="video"
              rules={[
                {
                  required: true,
                  message: "Please upload a video!",
                },
              ]}
            >
              <Upload accept="video/*" beforeUpload={beforeUpload} showUploadList={true} onChange={handleVideoUpload}>
                <Button icon={<UploadOutlined />}>Upload Video</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} disabled={videoNotUploaded}>
              {t("table.upload")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    {/* </Flex> */}
    </Layout>
  );
};

export default Games;
