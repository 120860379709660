import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Cookies from "js-cookie";
import Axios from "axios";

const Areachart = () => {
  const data = [
    { name: "A", value: 20 },
    { name: "B", value: 15 },
    { name: "C", value: 25 },
    // Add more data points
  ];
  return (
    <div className="chart-container">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart width={600} height={300} data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area dataKey="value" fill="#726ecd" stroke="#726ecd" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Areachart;
