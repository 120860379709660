import React, { useState, useEffect, useRef } from "react";
import { Host, Port } from "../Routes/apiConfig";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Image, message, Checkbox } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import "./pages-css/login.css";
import logInImage from "../images/swin-logo&title-colored.svg";
import { useUserRole } from "../UserRoleContext";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setUserRole } = useUserRole();
  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    } else if (sessionKey) {
      navigate("/subscribers");
    }
  }, [navigate]);

  // State to track password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (values) => {
    const { username, password } = values;
    const hashedPassword = CryptoJS.MD5(password).toString();
    try {
      const response = await fetch(`${Host}:${Port}/admin/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "HYAZS-API-KEY":
            "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          username: username,
          password: hashedPassword,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const sessionKey = data.data.session_key;
        const role = data.data.role;
        Cookies.set("sessionKeySwin", sessionKey, {
          expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
        });

        if (!sessionKey) {
          message.error(`${data.message}`);
        } else if (role) {
          setUserRole(role);
          navigate("/subscribers");
        } else {
          message.error("There is No roles For this User");
        }
      } else {
        message.error("API request failed");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred while sending the API request");
    }
  };

  return (
    <div className="form-container">
      <Form
        form={form}
        name="loginForm"
        className="form"
        onFinish={handleSubmit}
      >
        <Image src={logInImage} alt="User Profile" preview={false} />
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please enter your username" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type={isPasswordVisible ? "text" : "password"} // Use the state to control password visibility
            placeholder="Password"
            iconRender={(visible) =>
              visible ? (
                <EyeOutlined onClick={togglePasswordVisibility} />
              ) : (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              )
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="submit-button">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
