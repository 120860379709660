import React, { useState, useEffect } from "react";
import { Host,Port} from '../Routes/apiConfig';
import { useNavigate } from "react-router-dom";
import { useLayout } from "../LayoutContext";
import { useTranslation } from "react-i18next";
import { LoadingOutlined,SmileOutlined,FrownOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import Axios from "axios";
import moment from 'moment';
import {Select,List,Menu,Badge,Tooltip,Table,DatePicker,Button,Modal,message,Form,Input,Space,Layout,Row,Col,Segmented,Spin,Result,Typography,} from "antd";
import MyHeader from "../compo/Header";
import DataDisplay from '../pages/states/UserInfoDisplay';
import './pages-css/Dashboard.css'

const Dashboard = ({colorTheme}) => {
  const { isLtr } = useLayout();
  const { t, i18n } = useTranslation();

  /********************************************************/
  const userId = localStorage.getItem('userId');
/*********************************************************/
const [data, setData] = useState();
const [predictionsData, setPredictionsData] = useState([]);
const [packagesData, setPackagesData] = useState([]);
const [transactionsData, setTransactionsData] = useState([]);
const [loading, setLoading] = useState(true); // Add loading state
const [transactionsLoading, setTransactionsLoading] = useState(); // Add loading state
const [selectedOption, setSelectedOption] = useState("Predictions info");
const [predictionsStartDate, setPredictionsStartDate] = useState(null);
const [predictionsEndDate, setPredictionsEndDate] = useState(null);
const [packagesStartDate, setPackagesStartDate] = useState(null);
const [packagesEndDate, setPackagesEndDate] = useState(null);
const [transactionsStartDate, setTransactionsStartDate] = useState(null);
const [transactionsEndDate, setTransactionsEndDate] = useState(null);
const [buttonDisabled, setButtonDisabled] = useState();
const [predictionsStateSelectValue, setPredictionsStateSelectValue] = useState("all");
const [packagesStateSelectValue, setPackagesStateSelectValue] = useState("all");
/*********************************************************/
  // Define styles based on colorTheme
  const divStyle = {
    backgroundColor: colorTheme === 'dark' ? '#001529' : '#fff',
    color: colorTheme === 'dark' ? '#fff' : '#333',
    // height: '100vh',
    transition: 'background-color 0.2s, color 0.2s', // Add transition effect
  };
  const userProfileDivStyle = {
    marginBottom: '20px', // Adjust the value as needed
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // textAlign: 'center'
  };

  const navigate = useNavigate();

  const sessionKey = Cookies.get("sessionKeySwin");
  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);

  
  useEffect(() => {
      fetchData();
  }, []);

  useEffect(() => {
      if (!userId) {//if there is no userId so don get in the subscriber_info_page
      localStorage.removeItem('userId');
      navigate("/subscribers");
    }
  }, []);




//!----------↓ Your component's Headers ↓-----------

  const subscriberProfileHeaders={
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY":
        "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    }
  };
  const predictionsHeaders = {
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
    params: {
      fromDate: predictionsStartDate?.format("YYYY-M-D"),
      toDate: predictionsEndDate?.format("YYYY-M-D"),
    },
  };
  const transactionsHeaders = {
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
    params: {
      fromDate: transactionsStartDate?.format("YYYY-M-D"),
      toDate: transactionsEndDate?.format("YYYY-M-D"),
    },
  };
  const packagesHeaders = {
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
    params: {
      fromDate: packagesStartDate?.format("YYYY-M-D"),
      toDate: packagesEndDate?.format("YYYY-M-D"),
    },
  };
  const packagesDetailsHeaders = {
    headers: {
      "hyazs-session-key": sessionKey,
      "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
    },
  };

//!----------↑ Your component's Headers ↑-----------

  const fetchData = async () => {
    try {
      const getsubscriberProfileApi = `${Host}:${Port}/admin/subscribers/${userId?userId:''}`;
      console.log("The api you call is:",getsubscriberProfileApi)
      const response = await Axios.get(getsubscriberProfileApi, subscriberProfileHeaders);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else {
        setData(response.data.data);
        setLoading(false); // Set loading to false after fetching data
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  /***************************************************************************** */
  const items = [
    {
      key: '1',
      label: t("formLabels.usersInfo.Id"),
      children: `${userId ? userId : 'No Data'}`,
      span: 2
    },
    {
      key: '2',
      label: <Badge color="purple" status="processing" text={t("formLabels.usersInfo.name")}/>,
      children: `${data ? (data.name ? data.name : 'No Data') : 'No Data source'}`,
      span: 2
    },
    {
      key: '3',
      label: <Badge color="purple" status="processing" text={t("formLabels.usersInfo.userName")}/>,
      children: `${data ? data.userName? data.userName : 'No Data' : 'No Data source'}`,
      span: 2
    },
    {
      key: '4',
      label: t("formLabels.usersInfo.numberOfPredictions"),
      children: `${data ?data.number_of_predictions : 'No Data'}`,
      span: 1.5
    },
    {
      key: '5',
      label: t("formLabels.usersInfo.failurePredictions"),
      children: `${data ? data.failure_predictions : 'No Data'}`,
      span: 1.5
    },
    {
      key: '6',
      label: t("formLabels.usersInfo.successPredictions"),
      children: `${data ? data.success_predictions : 'No Data'}`,
      span: 1.5
    },
    {
      key: '7',
      label: t("formLabels.usersInfo.waitingPredictions"),
      children: `${data ? data.waiting_predictions : 'No Data'}`,
      span: 1.5
    },
    {
      key: '8',
      label: t("formLabels.usersInfo.numberOfPackages"),
      children: `${data ? data.number_of_package : 'No Data' }`,
      span: 1.5
    },
    {
      key: '9',
      label: t("formLabels.usersInfo.failurePackages"),
      children: `${data  ? data.failure_package : 'No Data'}`,
      span: 1.5
    },
    {
      key: '10',
      label: t("formLabels.usersInfo.successPackages"),
      children: `${data ? data.success_package : 'No Data'}`,
      span: 1.5
    },
    {
      key: '11',
      label: t("formLabels.usersInfo.waitingPackages"),
      children: `${data? data.waiting_package : 'No Data'}`,
      span: 1.5
    },
    {
      key: '12',
      label: t("formLabels.usersInfo.total_goals"),
      children: `${data ? data.goals.total_goals : 'No Data'}`,
      span: 1.5
    },
    {
      key: '13',
      label: t("formLabels.usersInfo.predictions_goals"),
      children: `${data? data.goals.predictions_goals : 'No Data'}`,
      span: 1.5
    },
    {
      key: '14',
      label: t("formLabels.usersInfo.packages_goals"),
      children: `${data? data.goals.packages_goals : 'No Data'}`,
      span: 1.5
    },
    {
      key: '15',
      label: t("formLabels.usersInfo.wheel_goals"),
      children: `${data? data.goals.wheel_goals : 'No Data'}`,
      span: 1.5
    },
    {
      key: '16',
      label: t("formLabels.usersInfo.code_goals"),
      children: `${data ? data.goals.code_goals : 'No Data' }`,
      span: 2
    },
    {
      key: '17',
      label: t("formLabels.usersInfo.gifts_goals"),
      children: `${data  ? data.goals.gifts_goals : 'No Data'}`,
      span: 2
    },
    {
      key: '18',
      label: t("formLabels.usersInfo.delivery_amount"),
      children: `${data? data.goals.delivery_amount : 'No Data'}`,
      span: 2
    }
  ];
  
  /***************************************************************************** */

  //Tk- Handler function to capture selected Segmented option value
  const handleSegmentedChange = value => {
    setSelectedOption(value);
  };

    useEffect(() => {
      if (predictionsData.length >= 1) {
        setPredictionsData([]);
      }
      if (packagesData.length >= 1) {
        setPackagesData([]);
      }
      if (transactionsData.length >= 1) {
        setTransactionsData([]);
      }
      
    }, [selectedOption]);
/*****************************Subscriber_predictions_fetch**************************************/

  const fetchSubscriberPredictionsData = async () => {
    try {
      const subscriberPredictionsApi=`${Host}:${Port}/admin/subscribers/predictions/${predictionsStateSelectValue}/${userId?userId:''}`;
      setLoading(true);
      const response = await Axios.get(subscriberPredictionsApi,predictionsHeaders);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        setPredictionsData("error");
        message.error(response.data.message);

      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");

      } else if(response.data.data ==="no data") {
        setPredictionsData("no data");

      }else{
        setPredictionsData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setLoading(false); // Set loading to false once the data is fetched
    }
  };

  const handleStartPredictionsDateChange = (date) => {
    setPredictionsStartDate(date);
    if(date){
      localStorage.removeItem('Prediction-START-DATE');
      localStorage.setItem('Prediction-START-DATE', date?.format("YYYY-M-D"));
    }
  };

  const handleEndPredictionsDateChange = (date) => {
    setPredictionsEndDate(date);
    if(date){
      localStorage.removeItem('Prediction-END-DATE');
      localStorage.setItem('Prediction-END-DATE', date?.format("YYYY-M-D"));
    }
  };
  const handleFetchSubscriberPredictionDataClick = () => {
    fetchSubscriberPredictionsData();
  };
/*****************************Subscriber_packages_fetch**************************************/

    const fetchSubscriberPackagesData = async () => {
      try {
        const subscriberPackagesApi=`${Host}:${Port}/admin/subscribers/package/${packagesStateSelectValue}/${userId?userId:''}`;
        setLoading(true);
        const response = await Axios.get(subscriberPackagesApi,packagesHeaders);
        if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
          setPackagesData("error");
          message.error(response.data.message);

        } else if (response.data.statusCode === -101) {
          Cookies.remove("sessionKeySwin");
          navigate("/login");
        }
        else if (response.data.data === "no data"){
          setPackagesData("no data");
        }else{
          setPackagesData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };
  
    const handlePackageStartDateChange = (date) => {
      setPackagesStartDate(date);
      if(date){
        localStorage.removeItem('Package-START-DATE');
        localStorage.setItem('Package-START-DATE', date?.format("YYYY-M-D"));
      }
    };
  
    const handlePackageEndDateChange = (date) => {
      setPackagesEndDate(date);

      if(date){
        localStorage.removeItem('Package-END-DATE');
        localStorage.setItem('Package-END-DATE', date?.format("YYYY-M-D"));
      }
    };

    const handleFetchSubscriberPackagesDataClick = () => {
      fetchSubscriberPackagesData();
    };
    
/*****************************Subscriber_transactions_fetch**************************************/

    const fetchSubscriberTransactionsData = async () => {
      setTransactionsLoading(true);
      try {
        const subscriberTransactionsApi=`${Host}:${Port}/admin/subscribers/transactions/${userId?userId:''}`;
        const response = await Axios.get(subscriberTransactionsApi,transactionsHeaders);
        if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
          setTransactionsData("error");
          message.error(response.data.message);

        } else if (response.data.statusCode === -101) {
          Cookies.remove("sessionKeySwin");
          navigate("/login");

        } else if (response.data.data === "no data"){
          setTransactionsData("no data");
        }
        else{
          setTransactionsData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally{
        setTransactionsLoading(false);
      }
    };
  
    const handleTransactionStartDateChange = (date) => {
      setTransactionsStartDate(date);
      if(date){
        localStorage.removeItem('Transaction-START-DATE');
        localStorage.setItem('Transaction-START-DATE', date?.format("YYYY-M-D"));
      }
    };
  
    const handleTransactionEndDateChange = (date) => {
      setTransactionsEndDate(date);
      if(date){
        localStorage.removeItem('Transaction-END-DATE');
        localStorage.setItem('Transaction-END-DATE', date?.format("YYYY-M-D"));
      }
    };
    const handleFetchSubscriberTransactionsDataClick = () => {
      fetchSubscriberTransactionsData();
    };
    
/*****************************Subscriber_packages_details_fetch**************************************/

  const fetchSubscriberPackagesDetails = async (id, type) => {
    setButtonDisabled(true);
    const body = {needed: type ? type : ''};
    
    const subscriberPackagesDetailsApi = `${Host}:${Port}/admin/predictions/package/${id ? id : ''}`;
    try {
      const response = await Axios.post(subscriberPackagesDetailsApi, body, packagesDetailsHeaders);
      if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
        message.error(response.data.message);
      } else if (response.data.statusCode === -101) {
        Cookies.remove("sessionKeySwin");
        navigate("/login");
      } else if (type === "games") {
        await handleFetchSubscriberPackagesDetails(response.data.data,type,id)
      } else if (type === "predictions") {
        await handleFetchSubscriberPackagesDetails(response.data.data,type,id)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data");
    } finally{
      setButtonDisabled(false);
    }
  };

/************************Tables-Columns************************************/
  const predictionsColumns = [
    // {
    //   title: 'id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (value,rowData) => (
    //     <Button type="link" onClick={() => handleNameClick(value,rowData)}>
    //       {value}
    //     </Button>
    //   )
    // },
    {
      title: 'game',
      dataIndex: 'game',
      key: 'game',
    },
    {
      title: 'guess',
      dataIndex: 'guess',
      key: 'guess',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Event time',
      dataIndex: 'event_time',
      key: 'event_time',
      render: (value) => {
        return value !== null ? moment(value).format("YYYY-MM-DD") : "none";
      },
    },
    {
      title: 'Create time',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (value) => {
        return value !== null ? moment(value).format("YYYY-MM-DD") : "none";
      },
    },
    {
      title: 'competition display name',
      dataIndex: 'competition_display_name',
      key: 'competition_display_name',
    },
    {
      title: 'away competitor',
      dataIndex: 'away_competitor',
      key: 'away_competitor',
    },
    {
      title: 'home competitor',
      dataIndex: 'home_competitor',
      key: 'home_competitor',
    },
    {
      title: 'expected profit',
      dataIndex: 'expected_profit',
      key: 'expected_profit',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    }
  ];

  const packagesColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    // {
    //   title: 'Games',
    //   dataIndex: 'games',
    //   key: 'games',
    //   ellipsis: true,
    //   render: (games,rowData) => (
    //     <div>
    //       <Button loading={buttonDisabled} type="link" onClick={() => handleNameClick(rowData.id,"games")}>
    //           {games.map((game, index) => (
    //           <li key={index}>{game}</li>
    //             ))}
    //       </Button>
    //     </div>
    //   )
    // },
    {
      title: 'Games',
      dataIndex: 'games',
      key: 'games',
      ellipsis: true, // Enable ellipsis for overflow text
      render: (games, rowData) => (
        <Tooltip placement="topLeft" title={games.join('-')} color={"#49557d"}>
              <Button
                type="link"
                onClick={() => handleNameClick(rowData.id, "games")}
              >
                {games.length >1? games[0]+'...':games[0]}
              </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Predictions',
      dataIndex: 'predictions',
      key: 'predictions',
      ellipsis: true,
      // render: (predictions,rowData) => (
      //   <div>
      //       <Button loading={buttonDisabled} type="link" onClick={() => handleNameClick(rowData.id,"predictions")}>
      //           {predictions.map((prediction, index) => (
      //           <li key={index}>{prediction}</li>
      //             ))}
      //       </Button>
      //   </div>
      // )
      render: (predictions, rowData) => (
        <Tooltip placement="topLeft" title={predictions.join('-')} color={"#49557d"}>
              <Button
                type="link"
                onClick={() => handleNameClick(rowData.id, "predictions")}
              >
                {predictions.length >1? predictions[0]+'...':predictions[0]}
              </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Guess',
      dataIndex: 'guess',
      key: 'guess',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Create Time',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (value) => {
        return value !== null ? moment(value).format("YYYY-MM-DD") : "none";
      },
    },
    {
      title: 'Expected Profit',
      dataIndex: 'expected_profit',
      key: 'expected_profit',
    },
  ];

  const transactionsColumns = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Target Text',
        dataIndex: 'target_text',
        key: 'target_text',
    },
    {
        title: 'Creation Time',
        dataIndex: 'creation_time',
        key: 'creation_time',
    }
];

  const packageDetailsColumns_gamesType = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (value) => {
        return value !== null ? moment(value).format("YYYY-MM-DD") : "none";
      },
    },
    {
      title: 'Away Competitor',
      dataIndex: 'awayCompetitor',
      key: 'awayCompetitor',
    },
    {
      title: 'Home Competitor',
      dataIndex: 'homeCompetitor',
      key: 'homeCompetitor',
    },
    {
      title: 'Competition Display Name',
      dataIndex: 'competitionDisplayName',
      key: 'competitionDisplayName',
    },
  ];

  const packageDetailsColumns_predictionsType = [
    {
      title: 'Game',
      dataIndex: 'game',
      key: 'game'
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (value) => {
        return value !== null ? moment(value).format("YYYY-MM-DD") : "none";
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Guess',
      dataIndex: 'guess',
      key: 'guess'
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit'
    },
    {
      title: 'Expected Profit',
      dataIndex: 'expected_profit',
      key: 'expected_profit'
    }
  ];


  
  
  const handleNameClick =  async (id, type) => {
   await fetchSubscriberPackagesDetails(id, type);
  };
  
  const handleFetchSubscriberPackagesDetails = async (packagesData,type,id) => {
    const EX = `[[[[${type}]]]]`;
      Modal.info({
        title: "Clicked Name",
        content: (
          <div>
            {!buttonDisabled &&
              <div>
                {type === "games" && packagesData.length > 0 && (
                  <Table
                    className="injected_table"
                    dataSource={packagesData}
                    columns={packageDetailsColumns_gamesType}
                    scroll={{ x: '100%' }}
                    pagination={{ defaultPageSize: 4 }}
                  />
                )}
                {type === "predictions" && packagesData.length > 0 && (
                  <Table
                    className="injected_table"
                    dataSource={packagesData}
                    columns={packageDetailsColumns_predictionsType}
                    scroll={{ x: '100%' }}
                    pagination={{ defaultPageSize: 4 }}
                  />
                )}
                {/* <p>
                  You clicked on the prediction that has the id: {id} and some processes
                  made on it and the result is : {EX}
                </p> */}
              </div>
            }
          </div>
        ),
        width: "70%",
        onOk() {},
      });
  };
/*******************************__Handle predictions state__**************************************************/
const predictionsStateOptions=[
  {
    value: 'all',
    label: t("formLabels.predictionsInfo.all"),
  },
  {
    value: 'waiting',
    label: t("formLabels.predictionsInfo.waiting"),
  },
  {
    value: 'success',
    label: t("formLabels.predictionsInfo.success"),
  },
  {
    value: 'failure',
    label: t("formLabels.predictionsInfo.failure"),
  },
]
  const handlePredictionsStateSelectChange = (selected) => {
    setPredictionsStateSelectValue(selected.value);
  };
  /*******************************__Handle packages state__**************************************************/
  const packagesStateOptions=[
    {
      value: 'all',
      label: t("formLabels.predictionsInfo.all"),
    },
    {
      value: 'waiting',
      label: t("formLabels.predictionsInfo.waiting"),
    },
    {
      value: 'success',
      label: t("formLabels.predictionsInfo.success"),
    },
    {
      value: 'failure',
      label: t("formLabels.predictionsInfo.failure"),
    },
  ]
    const handlePackagesStateSelectChange = (selected) => {
      setPackagesStateSelectValue(selected.value);
    };
  return (
    <Layout dir={isLtr ? "ltr" : "rtl"}>
    <div style={divStyle}>
      <MyHeader
        DrawerTitle="This is the drawer title"
        DrawerFooter="This is the drawer footer"
      />  {loading ? 
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center',marginTop: '20px', }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}/>
            </div>
              :
            <>
              <div style={userProfileDivStyle}>
                    <DataDisplay title={""} items={items} column={5} size={"small"} bordered={true}/>
              </div>

              <div className="info">
              <Segmented options={[t("formLabels.usersInfo.predictionsInfo"), t("formLabels.usersInfo.packagesInfo"),t("formLabels.usersInfo.Transactions")]} block defaultValue={selectedOption} onChange={handleSegmentedChange}/>

                {selectedOption === t("formLabels.usersInfo.predictionsInfo") && predictionsData==="No Data" && (
                  <>
                    <Result
                      status="500"
                      title="something went wrong"
                      subTitle="Sorry, try again please. !"
                      extra={<Button type="primary" onClick={() => setPredictionsData([])}>Try again</Button>}
                    />
                  </>
                  )}
                {selectedOption === t("formLabels.usersInfo.predictionsInfo") && Array.isArray(predictionsData) && predictionsData.length === 0 && (
                  <>
                    <Result
                      icon={<SmileOutlined style={{ color: "#67749c" }}/>}
                      title={t("formLabels.usersInfo.searchInPredictionsDate")}
                      extra={ 
                        <Space> 
                          <DatePicker 
                            placeholder={t("button.startDate")} 
                            onChange={handleStartPredictionsDateChange} 
                            value={predictionsStartDate?predictionsStartDate:null}
                          /> 
                          <DatePicker 
                            placeholder={t("button.EndDate")}
                            onChange={handleEndPredictionsDateChange}
                            value={predictionsEndDate?predictionsEndDate:null}
                          />
                          <Select
                            labelInValue
                            defaultValue={{
                              value: 'all',
                            }}
                            style={{
                              width: 150,
                            }}
                            onChange={handlePredictionsStateSelectChange}
                            options={predictionsStateOptions}
                            disabled={!predictionsStartDate || !predictionsEndDate}
                          />
                          <Button
                            type="primary"
                            disabled={!predictionsStartDate || !predictionsEndDate}
                            onClick={handleFetchSubscriberPredictionDataClick}
                          >
                            {t("button.know_the_statistics")}
                          </Button>
                        </Space>
                      }
                    />
                  </>
                  )}
                {selectedOption === t("formLabels.usersInfo.predictionsInfo") && predictionsData ==="no data" && (
                  <div>
                    <Result
                      icon={<FrownOutlined style={{ color: "#67749c" }}/>}
                      title={<Typography.Title level={4} strong>there are no predictions between "{localStorage.getItem('Prediction-START-DATE')}" and "{localStorage.getItem('Prediction-END-DATE')}" date !</Typography.Title>}
                      extra={
                        <Space>
                          <DatePicker
                            placeholder={t("button.startDate")}
                            onChange={handleStartPredictionsDateChange}
                            value={predictionsStartDate?predictionsStartDate:null}
                          />
                          <DatePicker
                            placeholder={t("button.EndDate")}
                            onChange={handleEndPredictionsDateChange}
                            value={predictionsEndDate?predictionsEndDate:null}
                          />
                          <Select
                            labelInValue
                            defaultValue={predictionsStateSelectValue}
                            style={{
                              width: 150,
                            }}
                            onChange={handlePredictionsStateSelectChange}
                            options={predictionsStateOptions}
                            disabled={!predictionsStartDate || !predictionsEndDate}
                          />
                          <Button
                             type="primary"
                            disabled={!predictionsStartDate || !predictionsEndDate}
                            onClick={handleFetchSubscriberPredictionDataClick}
                          >
                            {t("button.know_the_statistics")}
                          </Button>
                        </Space>
                      }
                    />
                  </div>
                  )}
                {selectedOption === t("formLabels.usersInfo.predictionsInfo") && Array.isArray(predictionsData) && predictionsData.length > 0 &&(
                  <div style={{ textAlign: 'center' }}>
                    <Space style={{ margin: '20px 0' }}>
                    <Typography.Text type="secondary" strong mark>From Date: {localStorage.getItem('Prediction-START-DATE')} To Date: {localStorage.getItem('Prediction-END-DATE')}</Typography.Text>
                      <DatePicker
                        placeholder={t("button.startDate")}
                        onChange={handleStartPredictionsDateChange}
                        value={predictionsStartDate?predictionsStartDate:''}
                      />
                      <DatePicker
                        placeholder={t("button.EndDate")}
                        onChange={handleEndPredictionsDateChange}
                        value={predictionsEndDate?predictionsEndDate:''}
                      />
                      <Select
                        labelInValue
                        defaultValue={predictionsStateSelectValue}
                        style={{
                          width: 150,
                        }}
                        onChange={handlePredictionsStateSelectChange}
                        options={predictionsStateOptions}
                        disabled={!predictionsStartDate || !predictionsEndDate}
                      />
                      <Button
                        type="primary"
                        disabled={!predictionsStartDate || !predictionsEndDate}
                        onClick={handleFetchSubscriberPredictionDataClick}
                      >
                        {t("button.know_the_statistics")}
                      </Button>
                    </Space>
                    <Table className="injected_table" dataSource={predictionsData} columns={predictionsColumns} scroll={{ x: '100%' }}/>
                  </div>
                  )}
{/***********************************************************************************************/}
                {selectedOption === t("formLabels.usersInfo.packagesInfo") && packagesData==="error" && (
                  <>
                    <Result
                      status="500"
                      title="something went wrong"
                      subTitle="Sorry, try again please. !"
                      extra={<Button type="primary" onClick={() => setPackagesData([])}>Try again</Button>}
                    />
                  </>
                  )}
                {selectedOption === t("formLabels.usersInfo.packagesInfo") && Array.isArray(packagesData) && packagesData.length === 0 && (
                  <>
                    <Result
                      icon={<SmileOutlined style={{ color: "#67749c" }}/>}
                      title={t("formLabels.usersInfo.searchInPackagesDate")}
                      extra={ 
                        <Space> 
                          <DatePicker 
                            placeholder={t("button.startDate")} 
                            onChange={handlePackageStartDateChange}
                            value={packagesStartDate?packagesStartDate:null}
                          /> 
                          <DatePicker 
                            placeholder={t("button.EndDate")}
                            onChange={handlePackageEndDateChange}
                            value={packagesEndDate?packagesEndDate:null}
                          />
                          <Select
                            labelInValue
                            defaultValue={packagesStateSelectValue}
                            style={{
                              width: 150,
                            }}
                            onChange={handlePackagesStateSelectChange}
                            options={packagesStateOptions}
                            disabled={!packagesStartDate || !packagesEndDate}
                          />
                          <Button
                            type="primary"
                            disabled={!packagesStartDate || !packagesEndDate}
                            onClick={handleFetchSubscriberPackagesDataClick}
                          >
                            {t("button.know_the_statistics")}
                          </Button>
                        </Space>
                      }
                    />
                  </>
                  )}
                {selectedOption === t("formLabels.usersInfo.packagesInfo") && packagesData==="no data" &&(
                  <div>
                    <Result
                      icon={<FrownOutlined style={{ color: "#67749c" }}/>}
                      title={<Typography.Title level={4} strong>there are no Packages between "{localStorage.getItem('Package-START-DATE')}" and "{localStorage.getItem('Package-END-DATE')}" date !</Typography.Title>}
                      extra={
                        <Space>
                          <DatePicker
                            placeholder={t("button.startDate")}
                            onChange={handlePackageStartDateChange}
                            value={packagesStartDate?packagesStartDate:null}
                          />
                          <DatePicker
                            placeholder={t("button.EndDate")}
                            onChange={handlePackageEndDateChange}
                            value={packagesEndDate?packagesEndDate:null}
                          />
                          <Select
                            labelInValue
                            defaultValue={packagesStateSelectValue}
                            style={{
                              width: 150,
                            }}
                            onChange={handlePackagesStateSelectChange}
                            options={packagesStateOptions}
                            disabled={!packagesStartDate || !packagesEndDate}
                          />
                          <Button
                             type="primary"
                            disabled={!packagesStartDate || !packagesEndDate}
                            onClick={handleFetchSubscriberPackagesDataClick}
                          >
                            {t("button.know_the_statistics")}
                          </Button>
                        </Space>
                      }
                    />
                  </div>
                  )}
                {selectedOption === t("formLabels.usersInfo.packagesInfo") && Array.isArray(packagesData) && packagesData.length > 0 &&(
                  <div style={{ textAlign: 'center' }}>
                    <Space style={{ margin: '20px 0' }}>
                    <Typography.Text type="secondary" strong mark>From Date: {localStorage.getItem('Package-START-DATE')} To Date: {localStorage.getItem('Package-END-DATE')}</Typography.Text>
                      <DatePicker
                        placeholder={t("button.startDate")}
                        onChange={handlePackageStartDateChange}
                        value={packagesStartDate?packagesStartDate:null}
                      />
                      <DatePicker
                        placeholder={t("button.EndDate")}
                        onChange={handlePackageEndDateChange}
                        value={packagesEndDate?packagesEndDate:null}
                      />
                      <Select
                        labelInValue
                        defaultValue={packagesStateSelectValue}
                        style={{
                          width: 150,
                        }}
                        onChange={handlePackagesStateSelectChange}
                        options={packagesStateOptions}
                        disabled={!packagesStartDate || !packagesEndDate}
                      />
                      <Button
                        type="primary"
                        disabled={!packagesStartDate || !packagesEndDate}
                        onClick={handleFetchSubscriberPackagesDataClick}
                      >
                        {t("button.know_the_statistics")}
                      </Button>
                    </Space>
                    <Table className="injected_table" dataSource={packagesData} columns={packagesColumns} scroll={{ x: '100%' }}/>
                  </div>
                  )}
{/***********************************************************************************************/}
                
                {selectedOption === t("formLabels.usersInfo.Transactions") && transactionsData==="error" && (
                  <>
                    <Result
                      status="500"
                      title="something went wrong"
                      subTitle="Sorry, try again please. !"
                      extra={<Button type="primary" onClick={() => setTransactionsData([])}>Try again</Button>}
                    />
                  </>
                  )}
                {selectedOption === t("formLabels.usersInfo.Transactions") && Array.isArray(transactionsData) && transactionsData.length === 0 && (
                  <>
                    <Result
                      icon={<SmileOutlined style={{ color: "#67749c" }}/>}
                      title={t("formLabels.usersInfo.searchInTransactionsDate")}
                      extra={ 
                        <Space> 
                          <DatePicker 
                            placeholder={t("button.startDate")} 
                            onChange={handleTransactionStartDateChange}
                            value={transactionsStartDate?transactionsStartDate:null} 
                          /> 
                          <DatePicker 
                            placeholder={t("button.EndDate")}
                            onChange={handleTransactionEndDateChange}
                            value={transactionsEndDate?transactionsEndDate:null}
                          />
                          <Button
                            loading={transactionsLoading}
                            type="primary"
                            disabled={!transactionsStartDate || !transactionsEndDate}
                            onClick={handleFetchSubscriberTransactionsDataClick}
                          >
                            {t("button.know_the_statistics")}
                          </Button>
                        </Space>
                      }
                    />
                  </>
                  )}
                {selectedOption === t("formLabels.usersInfo.Transactions") && transactionsData==="no data" &&(
                  <div>
                    <Result
                      icon={<FrownOutlined style={{ color: "#67749c" }}/>}
                      title={<Typography.Title level={4} strong>there are no Transactions between "{localStorage.getItem('Transaction-START-DATE')}" and "{localStorage.getItem('Transaction-END-DATE')}" date !</Typography.Title>}
                      extra={
                        <Space>
                          <DatePicker
                            placeholder={t("button.startDate")}
                            onChange={handleTransactionStartDateChange}
                          />
                          <DatePicker
                            placeholder={t("button.EndDate")}
                            onChange={handleTransactionEndDateChange}
                          />
                          <Button
                            loading={transactionsLoading}
                            type="primary"
                            disabled={!transactionsStartDate || !transactionsEndDate}
                            onClick={handleFetchSubscriberTransactionsDataClick}
                          >
                          {t("button.know_the_statistics")}
                          </Button>
                        </Space>
                      }
                    />
                  </div>
                  )}
                {selectedOption === t("formLabels.usersInfo.Transactions") && Array.isArray(transactionsData) && transactionsData.length > 0 &&(
                  <div style={{ textAlign: 'center' }}>
                    <Space style={{ margin: '20px 0' }}>
                    <Typography.Text type="secondary" strong mark>From Date: {localStorage.getItem('Transaction-START-DATE')} To Date: {localStorage.getItem('Transaction-END-DATE')}</Typography.Text>
                      <DatePicker
                        placeholder={t("button.startDate")}
                        onChange={handleTransactionStartDateChange}
                      />
                      <DatePicker
                        placeholder={t("button.EndDate")}
                        onChange={handleTransactionEndDateChange}
                      />
                      <Button
                        loading={transactionsLoading}
                        type="primary"
                        disabled={!transactionsStartDate || !transactionsEndDate}
                        onClick={handleFetchSubscriberTransactionsDataClick}
                      >
                        {t("button.know_the_statistics")}
                      </Button>
                    </Space>
                    <Table className="injected_table" dataSource={transactionsData} columns={transactionsColumns} scroll={{ x: '100%' }}/>
                  </div>
                  )}
              </div>
            </>  
          }
          

    </div>
    </Layout>
  );
};

export default Dashboard;
