import React from 'react';
import { Descriptions } from 'antd';

const UserInfoDisplay = ({ title, items,column,layout,size,height  }) => {
  return (
    <div style={{ height: height }}>
      <Descriptions
        title={title}
        bordered
        column={column}
        size={size}
        layout={layout}
        items={items}
      />
    </div>
  );
}

export default UserInfoDisplay;
