import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Cookies from "js-cookie";
import Axios from "axios";
import Mainn from "./pages/Mainn";
import Dashboard from "./pages/Dashboard";
import InternalUsers from "./pages/InternalUsers";
import Subscribers from "./pages/Subscribers";
import Competitions from "./pages/Competitions";
import Games from "./pages/Games";
import OnHoldGames from "./pages/OnHoldGames";
import CheckPredictions from "./pages/CheckPredictions";
import Products from "./pages/Products";
import Login from "./pages/Login";
import States from "./pages/states";
import DragInterface from "./pages/DragInterface";
import BuyBallsDaily from "./pages/states/BuyBallsDaily_";
import DailySubscribers from "./pages/states/DailySubscribers_";
import StatisticSubscriber from "./pages/states/StatisticSubscriber_";
import StatisticGames from "./pages/states/StatisticGames_";
import CompetitionsWinnings from "./pages/states/CompetitionsWinnings";
import SubscribersDailyInfo from "./pages/states/SubscribersDailyInfo";
import Menus from "./pages/Menus2";
import LogoIcon from "./compo/Logo";
import MyHeader from "./compo/Header";
import "../src/index.css";
import { LayoutProvider, useLayout } from "./LayoutContext";
import { UserRoleProvider } from './UserRoleContext';
import { Button, Layout } from "antd";
import {
  MenuOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import {createBrowserRouter,RouterProvider,BrowserRouter,Routes,Route,useNavigate,useLocation } from "react-router-dom";
import ToggleThemeButton from "./compo/ToggleThemeButton";
/******************************************************************************************************************/
//!---------------â†“Languages Set-Upâ†“---------------------//
import i18n from "i18next";
import { initReactI18next, useSSR } from "react-i18next";
import DrawWinners from "./pages/DrawWinners";
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: require("./languages/en.json") },
    ar: { translation: require("./languages/ar.json") },
  },
  lng: "en", // Set the default language
  fallbackLng: "en", // Fallback language in case of missing translations
});
//!----------â†“ Your Index Routes Here â†“-----------
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Mainn />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/test-only",
//     element: <TestYourCompo />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/dashboard",
//     element: <Dashboard />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/internal-users",
//     element: <InternalUsers />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/subscribers",
//     element: <Subscribers />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/competitions",
//     element: <Competitions />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/products",
//     element: <Products />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/states",
//     element: <States />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
//   {
//     path: "/login",
//     element: <Login />,
//     errorElement: (
//       <h3 style={{ color: "black" }}>Oops !! , there is nothing here !</h3>
//     ),
//   },
// ]);

//!----------â†‘ Your Index Routes Here â†‘-----------

const App = () => {
  /********************************************************************************************/
  const navigate = useNavigate();
  const { isLtr } = useLayout();
  const { Sider, Header } = Layout;
  const [darkThem, setDarkTheme] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  /***************************************************************/
    const location = useLocation();
  // Check if the current route is not /userInfo
  if (location.pathname !== "/userInfo") {
    // If not, remove the userId from local storage
    localStorage.removeItem('userId');
  }
  /***************************************************************/
  const toogleTheme = () => {
    setDarkTheme(!darkThem);
  };

  const siderStyle = {
    // boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: darkThem ? "#001529" : "#f4f4f4",
  };

  // Check if the current path is /subscribers
  const isLoginPage = window.location.pathname === "/login";
  const isDragInterface =window.location.pathname ==="/draw_interface";
  return (
    <Layout
      style={{
        minHeight: "100vh",
        flexDirection: isLtr ? "row" : "row-reverse",
      }}
    >
      {!isLoginPage && !isDragInterface && (
        <Sider
          style={siderStyle}
          collapsed={isClosed}
          // theme={darkThem ? "dark" : "light"}
          className="sideBar"
          width={250}
          breakpoint="xs"
        >
          <Button
            onClick={() => {setIsClosed(!isClosed);}}
            type="text"
            icon={
              isClosed ? (<MenuUnfoldOutlined style={{ color: darkThem ? "white" : "#001529" }}/>) :
               (<MenuFoldOutlined style={{ color: darkThem ? "white" : "#001529" }}/>)}/>
          <LogoIcon />
          {/* <ToggleThemeButton darkTheme={darkThem} toogleTheme={toogleTheme} /> */}
          <Menus
            logoutButton={isClosed}
            menuTheme={darkThem ? "dark" : "light"}
          />
        </Sider>
      )}
      <Layout>
        <Routes>
          <Route path="/userInfo" element={<Dashboard colorTheme={darkThem ? "dark" : "light"} />}></Route>
          <Route path="/internal-users" element={<InternalUsers colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/subscribers" element={<Subscribers colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/competitions" element={<Competitions colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/games" element={<Games colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/OnHoldGames" element={<OnHoldGames colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/predictions" element={<CheckPredictions colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/products" element={<Products colorTheme={darkThem ? "dark" : "light"}/>}></Route>
          <Route path="/states" element={<States />}></Route>
          <Route path="/states/buyBallsDaily" element={<BuyBallsDaily />}></Route>
          <Route path="/states/DailySubscribers" element={<DailySubscribers />}></Route>
          <Route path="/states/StatisticSubscriber" element={<StatisticSubscriber />}></Route>
          <Route path="/states/StatisticGames" element={<StatisticGames />}></Route>
          <Route path="/states/CompetitionsWinnings" element={<CompetitionsWinnings/>}></Route>
          <Route path="/states/SubscribersDailyInfo" element={<SubscribersDailyInfo/>}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/draw_interface" element={<DragInterface />}></Route>
          <Route path="/draw_winners" element={<DrawWinners />}></Route>
        </Routes>
      </Layout>
    </Layout>
  );
};

const RootComponent = () => {
  return (
    <React.StrictMode>
      <LayoutProvider>
        <UserRoleProvider> {/* Wrap your app with UserRoleProvider */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UserRoleProvider>
      </LayoutProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
