  import React, { useState, useEffect } from "react";
  import { Host,Port} from '../Routes/apiConfig';
  import { Menu, Layout,Button } from "antd";
  import Cookies from "js-cookie";
  import { Link, NavLink } from "react-router-dom";
  import { useNavigate ,useLocation } from "react-router-dom"; // Import useNavigate from react-router-dom
  import { useTranslation } from "react-i18next";
  import { useLayout } from "../../src/LayoutContext";
  import {HomeOutlined,UserSwitchOutlined,InfoCircleOutlined,UsergroupAddOutlined,TrophyOutlined,TagOutlined,DashboardOutlined,PieChartOutlined,LogoutOutlined,GlobalOutlined } from "@ant-design/icons";
  import '../index.css'
  import '../pages/pages-css/menus.css'
  import axios from "axios";
  import { useUserRole } from '../UserRoleContext';

  const Menus = ({menuTheme,logoutButton}) => {
    const { userRole } = useUserRole();
    const { isLtr, toggleLayoutDirection } = useLayout();
    const { t, i18n } = useTranslation();
    const sessionKey = Cookies.get("sessionKeySwin");
    const [logoutLoading, setlogoutLoading] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation(); // Get current location
    const currentlocation =location.pathname;
    
    const handleLogout = async () => {
      try {
        setlogoutLoading(true);
        const response = await axios.post(
          `${Host}:${Port}/admin/users/logout`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
              "Access-Control-Allow-Origin": "*",
              "hyazs-session-key": sessionKey,
            },
          }
        );
        if (response.data.statusCode < 0) {
          alert(response.data.message);
        } else {
          Cookies.remove("sessionKeySwin");
          localStorage.removeItem('userRole');
          navigate("/login");
        }
      } catch (error) {
        console.error("Logout Error:", error);
        alert("Logout failed. Please try again.");
      }finally{
        setlogoutLoading(false);
      }
    };
    
    const menuItems = [
      
      {
        key: "/internal-users",
        label: t("menu.internal_users"), // Translate the menu item label
        icon:<UserSwitchOutlined />
      },
      {
        key: "/subscribers",
        label: t("menu.subscribers"), // Translate the menu item label
        icon:<UsergroupAddOutlined />
      },
      {
        key: "/userInfo",
        label: t("menu.subscriber_info"), // Translate the menu item label
        icon :<InfoCircleOutlined />,
        disabled: currentlocation==="/userInfo"?false:true,
      },
      {
        key: "/competitions",
        label: t("menu.competitions"), // Translate the menu item label
        icon: <TrophyOutlined />
      },
      {
        key: "/FullGames",
        label: t("menu.games"), // Translate the menu item label
        icon: <TrophyOutlined />,
        submenu: [
          {
            key: "/games",
            label: t("menu.games"),
          },
          {
            key: "/onHoldGames",
            label: t("menu.OnHoldGames"),
          },
          {
            key: "/draw_interface",
            label: t("menu.drawInterface"),
          },
          {
            key: "/draw_winners",
            label: t("menu.DrawWinners"),
          },
        ]
      },
      {
        key: "/predictions",
        label: t("menu.predictions"), // Translate the menu item label
        icon: <GlobalOutlined/>
      },
      {
        key: "/products",
        label: t("menu.products"), // Translate the menu item label
        icon:<TagOutlined />
      },
      // {
      //   key: "/dashboard",
      //   label: t("menu.dashboard"), // Translate the menu item label
      //   icon:<DashboardOutlined />,
      // },
      {
        key: "/states",
        label: t("menu.states"), // Translate the menu item label
        icon:<PieChartOutlined />,
        submenu: [
          
          // {
          //   key: "/states/buyBallsDaily",
          //   label: t("menu.statesSubMenu.buyBallsDaily"),
          // },
          // {
          //   key: "/states/DailySubscribers",
          //   label: t("menu.statesSubMenu.subscriberPerDay"),
          // },
          // {
          //   key: "/states/StatisticSubscriber",
          //   label: t("menu.statesSubMenu.StatisticSubscriber"),
          // },
          // {
          //   key: "/states/StatisticGames",
          //   label: t("menu.statesSubMenu.StatisticGames"),
          // },
          {
            key: "/states/competitionsWinnings",
            label: t("menu.statesSubMenu.competitionsWinnings"),
          },
          {
            key: "/states/SubscribersDailyInfo",
            label: t("menu.statesSubMenu.SubscribersDailyInfo"),
          },
          // Add more submenu items as needed
        ],
      },
    ];
    const visitorMenuItems = [
      
      {
        key: "/subscribers",
        label: t("menu.subscribers"), // Translate the menu item label
        icon:<UsergroupAddOutlined />
      },
      {
        key: "/userInfo",
        label: t("menu.subscriber_info"), // Translate the menu item label
        icon :<InfoCircleOutlined />,
        disabled: currentlocation==="/userInfo"?false:true,
      },
      {
        key: "/Fullgames",
        label: t("menu.games"), // Translate the menu item label
        icon: <TrophyOutlined />,
        submenu: [
          {
            key: "/games",
            label: t("menu.games"),
          },
          {
            key: "/draw_interface",
            label: t("menu.drawInterface"),
          },
          {
            key: "/draw_winners",
            label: t("menu.DrawWinners"),
          },
        ]
      },
      {
        key: "/states",
        label: t("menu.states"), // Translate the menu item label
        icon:<PieChartOutlined />,
        submenu: [
          {
            key: "/states/competitionsWinnings",
            label: t("menu.statesSubMenu.competitionsWinnings"),
          },
          {
            key: "/states/SubscribersDailyInfo",
            label: t("menu.statesSubMenu.SubscribersDailyInfo"),
          },
          // Add more submenu items as needed
        ],
      },
    ];
    const testerMenuItems = [
      
      {
        key: "/subscribers",
        label: t("menu.subscribers"), // Translate the menu item label
        icon:<UsergroupAddOutlined />
      },
      {
        key: "/userInfo",
        label: t("menu.subscriber_info"), // Translate the menu item label
        icon :<InfoCircleOutlined />,
        disabled: currentlocation==="/userInfo"?false:true,
      },
      {
        key: "/Fullgames",
        label: t("menu.games"), // Translate the menu item label
        icon: <TrophyOutlined />,
        submenu: [
          {
            key: "/games",
            label: t("menu.games"),
          },
          {
            key: "/onHoldGames",
            label: t("menu.OnHoldGames"),
          },
          {
            key: "/draw_interface",
            label: t("menu.drawInterface"),
          },
          {
            key: "/draw_winners",
            label: t("menu.DrawWinners"),
          },
        ]
      },
      {
        key: "/states",
        label: t("menu.states"), // Translate the menu item label
        icon:<PieChartOutlined />,
        submenu: [
          {
            key: "/states/competitionsWinnings",
            label: t("menu.statesSubMenu.competitionsWinnings"),
          },
          {
            key: "/states/SubscribersDailyInfo",
            label: t("menu.statesSubMenu.SubscribersDailyInfo"),
          },
          // Add more submenu items as needed
        ],
      },
    ];

    const getMenuItems = () => {
      switch (userRole) {
        case "admin":
          return menuItems;
        case "visitor":
          return visitorMenuItems;
        case "tester":
          return testerMenuItems;
        default:
          Cookies.remove("sessionKeySwin");
          navigate("/login");
          return [];
      }
    };
    const menuItemsToRender = getMenuItems();

    useEffect(() => {
      // Use useNavigate to navigate to the current location
      currentlocation==="/"? navigate('/subscribers'):navigate(currentlocation);
  }, [currentlocation]); // Update on location change

    return (
      <Layout dir={!isLtr ? "rtl" : "ltr"}>
        <div>
          <Menu
            className="menu-bar"
            theme={menuTheme}
            mode="inline"
            selectedKeys={[currentlocation]}
          >
            {menuItemsToRender.map((item) =>
              item.submenu ? (
                <Menu.SubMenu key={item.key} title={item.label} icon={item.icon}>
                  {item.submenu.map((subItem) => (
                    <Menu.Item key={subItem.key}>
                      <Link to={subItem.key}>{subItem.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                  {!item.disabled?<Link to={item.key}>{item.label}</Link>:<Link>{item.label}</Link>}
                </Menu.Item>
              )
              
            )}
            
          </Menu>
          <div className="drawer-buttons" style={{background:menuTheme==="dark"?'#001529':''}}>
          {logoutButton ===false?
              <Button onClick={handleLogout} type="primary" loading={logoutLoading} danger ghost icon={<LogoutOutlined />}>
              {t("menu.logout")}
              </Button>
              :
              <Button onClick={handleLogout} type="primary" loading={logoutLoading} danger ghost icon={<LogoutOutlined />}>
              </Button>}
          </div>
        </div>
      </Layout>
    );
  };

  export default Menus;
