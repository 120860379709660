import React, { useState, useEffect } from "react";
import { Host,Port} from '../Routes/apiConfig';
import Cookies from "js-cookie";
import Axios from "axios";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";



const Chart = () => {

  const lineChartData = [
    { name: "A", value: 20 },
    { name: "B", value: 15 },
    { name: "C", value: 25 },
    { name: "D", value: 18 },
    { name: "E", value: 22 },
    { name: "F", value: 44 },
  ];
  
  const pieChartData = [
    { name: "A", value: 20 },
    { name: "B", value: 15 },
    { name: "C", value: 10 },
    { name: "D", value: 5 },
  ];
  
  const customColors = ["#726ecd", "#82ca9d", "#ffc658", "#ff7f0e"];
  return (
    <div className="chart-container">

      

      <h2>Pie Chart</h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieChartData}
            dataKey="value"
            nameKey="name"
            outerRadius={80}
            label
          >
            {pieChartData.map((entry, index) => (
              <Cell
                key={entry.name}
                fill={customColors[index % customColors.length]}
              />
            ))}
          </Pie>
          <Tooltip animationEasing="ease-out" />
          <Legend verticalAlign="top" align="right" layout="vertical" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
