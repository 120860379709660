import React, { useState, useEffect } from "react";
import { Host, Port } from "../../Routes/apiConfig";
import {Pagination,Card,Select,Menu,Table,DatePicker,Button,Modal,Dropdown,message,Form,Input,Space,Layout,Row,Col} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "axios";
import { useLayout } from "../../LayoutContext";
import moment from "moment";
import MyHeader from "../../compo/Header";
import Barchart from "../../Charts/BarChart";
const StatisticGames = () => {
  const { isLtr } = useLayout();
  const navigate = useNavigate();
  const sessionKey = Cookies.get("sessionKeySwin");

  useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchStatisticGamesChartData();
  }, []);
  // console.log("RETURNED DATA:",fetchDataaa())
  /************************************************************/
  const { Option, OptGroup } = Select;
  const [statisticType, setstatisticType] = useState("points");

    const handleChange = (value) => {
      setstatisticType(value);
      fetchStatisticGamesChartData(value ? { statisticType: value } : { statisticType: 'points' });
    }

  /************************customStyles**********************************/
  const containerStyle = {
    marginBlockStart: "2rem",
    marginInline: "1rem",
  };

  const customColors = ["#7684af"];

  const customStateHeader = () => (
    <div style={{ paddingBlock: "1.5rem", background: "#e5eaf9" }}>
      <span
        style={{
          background: "linear-gradient(to right, #7684af, #21daff)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "28px",
          fontWeight: "bold",
          paddingInlineStart: "1rem",
        }}
      >
        States
      </span>
    </div>
  );

  /**********************************Fetch Bar Chart Data*********************************************/
  const [StatisticGamesBarChartData, setStatisticGamesBarChartData] = useState([]);
  console.log(StatisticGamesBarChartData)
    const fetchStatisticGamesChartData = async ({ statisticType } = {}) => {
      try {
        const requestBody = {
          // Your data goes here
          type: statisticType? statisticType : "points",
        };
    
        const response = await Axios.put(
          `${Host}:${Port}/admin/statistic/games`,requestBody,
          {
            headers: {
              "hyazs-session-key": sessionKey,
              "HYAZS-API-KEY": "rYJ1riRCUqRHMwQH47UCOYwPnfigX1X-aeeTVYyxVTSWKsSup0VXS-K1",
            },
          }
        );
    
        if (response.data.statusCode < 0 && response.data.statusCode !== -101) {
          message.error(response.data.message);
        } else if (response.data.statusCode === -101) {
          Cookies.remove("sessionKeySwin");
          navigate("/login");
        } else {
          setStatisticGamesBarChartData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
  /************************paginationStylesAndControlers**********************************/
  // Pagination settings
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9); // Set the number of cards per page

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const paginatedData = StatisticGamesBarChartData.length >1 ?StatisticGamesBarChartData.slice(startIndex, endIndex):[];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  /************************paginationStylesAndControlers**********************************/
  const datakeysBars = ['costs','profits','difference'];
  const colors = ['#8884d8','#29a7c7','#299ac7'];
  const renderBarchart = () => {
    return (
      <div>
        <Row justify="center" align="middle">
          <Col span={50} key={statisticType}>
            {statisticType && (statisticType === "prediction") ? (
              <Row gutter={30}>
                {paginatedData.map((item) => (
                  <Col span={8} key={item.id_logins} style={{ marginBottom: '20px' }}>
                    <Card title={`ID: ${item.id_logins}`} bordered={true} style={{ padding: '0px' }}>
                      <p>Count: {item.count}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) :statisticType === "highest_prediction" && StatisticGamesBarChartData.length > 0 ?(
              <Card title={`ID: ${StatisticGamesBarChartData[0].game}`} bordered={true} style={{ padding: '0px' }}>
              <p>Count: {StatisticGamesBarChartData[0].count}</p>
            </Card>

            ):(statisticType === "highest_winner" || statisticType === "highest_losser") && StatisticGamesBarChartData.length > 0 ?(
              <Card title={`ID: ${StatisticGamesBarChartData[0].game}`} bordered={true} style={{ padding: '0px' }}>
              <p>costs: {StatisticGamesBarChartData[0].costs}</p>
              <p>profits: {StatisticGamesBarChartData[0].profits}</p>
              <p>difference: {StatisticGamesBarChartData[0].difference}</p>
            </Card>
            ): (
              <Barchart
                data={StatisticGamesBarChartData?StatisticGamesBarChartData:[]}
                xKey="game"
                yKey="profits"
                colors={colors}
                chartTitle={statisticType ? statisticType : "points"}
                width={800}
                height={500}
                ticks={false}
                barNumber={4}
                datakeysBarsValues={datakeysBars}
              />
            )}
          </Col>
        </Row>

        {/* Pagination Component */}
        {statisticType && statisticType === "prediction" && (
        <Pagination
          current={currentPage}
          total={StatisticGamesBarChartData.length}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{ marginTop: '20px', textAlign: 'center' }}
        />
      )}
      </div>
      
    );
  };
  
  return (
    <div>
      <Layout dir={isLtr ? "ltr" : "rtl"}>
        <MyHeader
          DrawerTitle="This is the drawer title"
          DrawerFooter="This is the drawer footer"
        />
        {customStateHeader()}
        <div style={containerStyle}>
          <div style={{ marginBlockStart: "1rem" }}>
            <Space>
            <Select defaultValue="points" style={{ width: 200 }} onChange={handleChange}>  
                  <Option value="points">points</Option>
                  <Option value="subscriber">subscriber</Option>
                  <Option value="loser_games">loser_games</Option>
                  <Option value="winner_games">winner_games</Option>
                  <Option value="even_games">even_games</Option>
                  <Option value="highest_prediction">highest_prediction</Option>
                  <Option value="highest_winner">highest_winner</Option>
                  <Option value="highest_losser">highest_losser</Option>

                {/* <OptGroup label="Engineer">
                  <Option value="Yiminghe">yiminghe</Option>
                </OptGroup> */}
            </Select>
            </Space>
          </div>
          {renderBarchart()}
            
        </div>
      </Layout>
    </div>
  );
};

export default StatisticGames;
